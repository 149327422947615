import { Component, ViewEncapsulation } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { PurchaseType } from "@enums/purchaseType.enum";
import { GetPropertiesFromEnum } from "@helpers/getPropertiesFromEnum";
import { PurchaseLogGridLineItem } from "@interfaces/purchaseLogGridLineItem.interface";
import { PurchaseLogService } from "@services/purchaseLog.service";
import { SiteService } from "@services/site.service";
import notify from "devextreme/ui/notify";

@Component({
	encapsulation: ViewEncapsulation.None,
	moduleId: module.id,
	selector: "outsideProcess",
	styleUrls: ["outsideProcess.css"],
	templateUrl: "outsideProcess.html"
})
export class OutsideProcessComponent {
	dateTime = new Date;
	direction = 0;
	firstLoad = true;
	isLoading = false;
	mode = 0;
	purchaseLogs: any[] = [];
	purchaseTypes: any[] = [];
	showPopup = false;
	site = "";
	siteId = 0;

	constructor(private route: ActivatedRoute, private router: Router, private siteService: SiteService, private plogService: PurchaseLogService) {
		this.purchaseTypes = GetPropertiesFromEnum(PurchaseType);
		this.route.params
			.subscribe(async (params) => {
				this.siteId = Number.parseInt(params.id);
				this.mode = Number.parseInt(params.mode);
				this.renderMode(Number.parseInt(params.mode));
			});
	}

	enterManual() {
		if (this.mode == 1) {
			const input = window.prompt("Enter PO Number", "0");
			this.router.navigate([`admin/webapps/purchaseDetail/${input}`]);
		}
	}

	getPlogs() {
		this.firstLoad = false;
		this.plogService.app_GetDue()
			.subscribe(
				(res: any) => {
					this.purchaseLogs = res.response;
				},
				(err) => {
					console.log(err);
					notify("Something Went Wrong!", "Error", 5000);
				}
			);
	}

	goBack() {
		if (this.direction == 0) {
			// Goods In
			this.router.navigate([`admin/webapps/goods/${this.siteId}/in`]);
		} else {
			// Goods Out
			this.router.navigate([`admin/webapps/goods/${this.siteId}/out`]);
		}
	}

	goPurchaseDetail(id:number){
		this.router.navigate([`admin/webapps/purchaseDetail/${id}`]);
	}

	renderMode(mode: number) {
		if (mode < 4) {
			this.direction = 0;
		} else {
			this.direction = 1;
		}
		switch (mode) {
			case 1:
				// In - Purchase
				if (this.firstLoad == true) {
					this.firstLoad = false;
					this.getPlogs();
				}
				return "Purchase";
			case 2:
				// In Outside Process
				return "Outside Process";
			case 3:
				// In - Internal
				return "Internal";
			case 4:
				//  Out - Suppliers
				return "Suppliers";
			case 5:
				// Out - Customer
				return "Customer";
			case 6:
				// Out - Internal
				return "Internal";
			default:
				this.direction = 999;
				return "UNKNOWN";
		}
	}

	renderPlogDueDate(date: string) {
		return new Date(date).toLocaleDateString("en-GB");
	}

	renderPlogLineItems(lineItems: string) {
		const lineItemsConverted: PurchaseLogGridLineItem[] = JSON.parse(lineItems);
		return (lineItemsConverted.length);
	}

	renderPlogType(id: number) {
		return this.purchaseTypes.filter(x => x.id == id)[0].name;
	}

	renderSite(id: number) {
		switch (id) {
			case 1:
				return "KINGS NORTON";
			case 2:
				return "SELLY OAK";
			case 3:
				return "REDDITCH";
			default:
				return "KINGS NORTON";
		}
	}
}
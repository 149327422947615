import { Injectable, Injector } from "@angular/core";
import { environment } from "@environments/environment";
import { CreateManualShippingNote } from "@interfaces/createManualShippingNote.interface";
import { DownloadShippingNoteLabels } from "@interfaces/downloadShippingNoteLabels.interface";
import { GenerateEdiShippingNote } from "@interfaces/generateEdiShippingNote.interface";
import { Service } from "@services/service";

@Injectable()
export class EdiShippingNoteService extends Service {
	constructor(injector: Injector) {
		super("EdiShippingNote", injector);
	}

	// API
	generateLabels(values: DownloadShippingNoteLabels) {
		return this.http.post(`${environment.server.apiURL}/${this.endpoint}/Labels`, values, { responseType: "blob" as "json"} );
	}

	generateMasterLabels(id: number) {
		return this.http.post(`${environment.server.apiURL}/${this.endpoint}/MasterLabels/${id}`, {}, { responseType: "blob" as "json"} );
	}

	generateNitraExportDocument(id: number) {
		return this.http.post(`${environment.server.apiURL}/${this.endpoint}/NitraExportDocument/${id}`, {}, { responseType: "blob" as "json"} );
	}

	generateShippingNote(values: GenerateEdiShippingNote) {
		return this.http.post(`${environment.server.apiURL}/${this.endpoint}/GenerateShippingNote`, values);
	}

	getLabelSizes(id: number) {
		return this.http.get(`${environment.server.apiURL}/${this.endpoint}/LabelSizes/${id}`);
	}

	lockShippingNote(id: number) {
		return this.http.post(`${environment.server.apiURL}/${this.endpoint}/Lock/${id}`, {});
	}
	
	manualCreate(data: CreateManualShippingNote) {
		return this.http.post(`${environment.server.apiURL}/${this.endpoint}/ManualCreate`, data );
	}

	markSent(id: number) {
		return this.http.post(`${environment.server.apiURL}/${this.endpoint}/MarkSent/${id}`, {});
	}

	sendMessage(id: number) {
		return this.http.post(`${environment.server.apiURL}/${this.endpoint}/CreateMessage/${id}`, {});
	}
}
import { PromiseDepartment } from "@enums/promiseDepartment.enum";
import { PromiseType } from "@enums/promiseType.enum";
import { Model } from "@interfaces/model.interface";

export interface Promise extends Model {
	completed: boolean;
	createdById: number;
	description: string;
	isPrivate: boolean;
	promiseDate: Date;
	promiseDepartment: PromiseDepartment;
	promiseType: PromiseType;
}

export class Promise implements Promise {
	promiseDate = new Date;
	promiseDepartment = PromiseDepartment.General;
	promiseType = PromiseType.External;
	
	constructor(data?: Partial<Promise>) {
		if (data) {
			for (const key of Object.keys(data)) {
				(this as any)[key] = (data as any)[key];
			}
		}
	}
}
import { Component } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";
import { ActivatedRoute,Router } from "@angular/router";
import { AdminHeaderButton } from "@interfaces/adminHeaderButton.interface";
import { HelpDocument } from "@interfaces/helpDocument.interface";
import { HelpDocumentService } from "@services/helpDocument.service";
import notify from "devextreme/ui/notify";

@Component({
	moduleId: module.id,
	selector: "help-id",
	styleUrls: ["help-id.css"],
	templateUrl: "help-id.html"
})
export class HelpIdAdminComponent {
	headerPrimaryButtons: AdminHeaderButton[] = [{ method: "close", text: "Close" }];
	item: HelpDocument = new HelpDocument();
	itemType = "Help Document";
	returnUrl: string = location.href.split("/")[3] + "/" + location.href.split("/")[4];

	constructor(private router: Router, private route: ActivatedRoute, private itemService: HelpDocumentService, private sanitizer: DomSanitizer) {
		this.route.params
			.subscribe((params) => {
				this.getItem(params.id);
			});
	}

	close() {
		this.router.navigate([`/${this.returnUrl}/`]);
	}

	frameUrl() {
		return this.sanitizer.bypassSecurityTrustResourceUrl(this.item.url);
	}

	getItem(itemId: number) {
		this.itemService.getSingleById(itemId)
			.subscribe(
				(res: any) => {
					this.item = res.response;
				},
				(err) => {console.log(err); notify("Something Went Wrong!", "Error", 5000);}
			);
	}

	headerButtonClick(method: any) {
		// @ts-ignore // Required to be able to call the method directly from the variable
		if (this[method]) this[method]();
	}
}
import { Component, ViewEncapsulation } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { SiteService } from "@services/site.service";

@Component({
	encapsulation: ViewEncapsulation.None,
	moduleId: module.id,
	selector: "goodsMenu",
	styleUrls: ["goodsMenu.css"],
	templateUrl: "goodsMenu.html"
})
export class GoodsMenuComponent {
	dateTime = new Date;
	isLoading = false;
	mode = "";
	showPopup = false;
	site = "";
	siteId = 0;

	constructor(private route: ActivatedRoute, private router: Router, private siteService: SiteService) {
		this.route.params
			.subscribe(async (params) => {
				this.mode = params.mode;
				this.siteId = Number.parseInt(params.id);
			});
		this.setSite(this.siteId);
	}

	navigateToInternal() {
		const implemented = false;
		if (implemented) {
			if (this.mode.toLowerCase() == "in") {
				this.router.navigate([`admin/webapps/goodsDetail/${this.siteId}/3`]);
			} else {
				this.router.navigate([`admin/webapps/goodsDetail/${this.siteId}/6`]);
			}
		} else {
			alert("Not yet implemented!");
		}

	}

	navigateToPage(id: number) {
		this.router.navigate([`admin/webapps/goodsDetail/${this.siteId}/${id}`]);
	}

	async setSite(id: number) {
		switch (id) {
			case 1:
				this.site = "KINGS NORTON";
				break;
			case 2:
				this.site = "SELLY OAK";
				break;
			case 3:
				this.site = "REDDITCH";
				break;
			default:
				this.site = "KINGS NORTON";
				break;
		}
	}

	switchMode() {
		if (this.mode.toLowerCase() == "in") {
			this.router.navigate([`admin/webapps/goods/${this.siteId}/out`]);
		} else {
			this.router.navigate([`admin/webapps/goods/${this.siteId}/in`]);
		}

	}
}
import { Component, ViewEncapsulation } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { Department } from "@enums/department";
import { PurchaseType } from "@enums/purchaseType.enum";
import { environment } from "@environments/environment";
import { GetPropertiesFromEnum } from "@helpers/getPropertiesFromEnum";
import { DeliveryNote } from "@interfaces/deliveryNote.interface";
import { PurchaseLogGridLineItem } from "@interfaces/purchaseLogGridLineItem.interface";
import { AppSettingsService } from "@services/appSettings.service";
import { MachineService } from "@services/machine.service";
import { PurchaseLogService } from "@services/purchaseLog.service";
import { SiteService } from "@services/site.service";
import { WorkOrderStageService } from "@services/workOrderStage.service";
import notify from "devextreme/ui/notify";

@Component({
	encapsulation: ViewEncapsulation.None,
	moduleId: module.id,
	selector: "goodsDetail",
	styleUrls: ["goodsDetail.css"],
	templateUrl: "goodsDetail.html"
})
export class GoodsDetailComponent {
	dateTime = new Date;
	deliveryNotes: DeliveryNote[] = [];
	direction = 0;
	firstLoad = true;
	isLoading = false;
	machines: any[] = [];
	mode = 0;
	purchaseLogSuppliers: any[] = [];
	purchaseLogs: any[] = [];
	purchaseLogsOrig: any[] = [];
	purchaseTypes: any[] = [];
	quantityEntered = 0;
	selectedDeliveryNote = new DeliveryNote;
	selectedStage: any;
	showCustomerSent = false;
	showOutsideRecieved = false;
	showOutsideSent = false;
	showPopup = false;
	site = "";
	siteId = 0;
	supplierSelected = false;

	constructor(private route: ActivatedRoute, private router: Router, private appSettingsService: AppSettingsService, private siteService: SiteService, private plogService: PurchaseLogService, private machineService: MachineService, private workOrderStageService: WorkOrderStageService) {
		this.purchaseTypes = GetPropertiesFromEnum(PurchaseType);
		this.appSettingsService.set("profileId", environment.build.flowId);
		this.route.params
			.subscribe(async (params) => {
				this.siteId = Number.parseInt(params.id);
				this.mode = Number.parseInt(params.mode);
				this.renderMode(Number.parseInt(params.mode));
			});
	}

	customerSent() {
		return "";
	}

	enterManual() {
		if (this.mode == 1) {
			const input = window.prompt("Enter PO Number", "0");
			this.router.navigate([`admin/webapps/purchaseDetail/${input}`]);
		}
	}

	getOutsideDue() {
		this.firstLoad = false;
		this.machineService.appOpsPlanner(Department.Outsource)
			.subscribe(
				(res: any) => {
					const machinesTemp = res.response;
					this.machines = machinesTemp.filter((x: { machineId: number; }) => x.machineId != null);
				},
				(err) => {
					console.log(err);
					notify("Something Went Wrong!", "Error", 5000);
				}
			);
	}

	getPlogs() {
		this.firstLoad = false;
		this.plogService.app_GetDue()
			.subscribe(
				(res: any) => {
					this.purchaseLogsOrig = res.response;
					this.purchaseLogsOrig.forEach((x) => {
						this.purchaseLogSuppliers.push({ id: x.supplierId, name: x.supplier.name });
					});
					// eslint-disable-next-line prefer-const
					this.purchaseLogSuppliers = this.purchaseLogSuppliers.filter((value, index, self) =>
						index === self.findIndex((t) => (
							t.id === value.id && t.name === value.name
						))
					);
				},
				(err) => {
					console.log(err);
					notify("Something Went Wrong!", "Error", 5000);
				}
			);
	}

	goBack() {
		if (this.direction == 0) {
			// Goods In
			this.router.navigate([`admin/webapps/goods/${this.siteId}/in`]);
		} else {
			// Goods Out
			this.router.navigate([`admin/webapps/goods/${this.siteId}/out`]);
		}
	}

	goMoveStock() {
		this.router.navigate(["admin/webapps/moveStock"]);
	}


	goPurchaseDetail(id: number) {
		this.router.navigate([`admin/webapps/purchaseDetail/${id}`]);
	}

	outsideRecieved() {
		console.log(this.quantityEntered);
		console.log(this.selectedStage.quantityCompleted);
		this.workOrderStageService.completedQuantityApiKey(this.selectedStage.id, this.quantityEntered - this.selectedStage.quantityCompleted)
			.subscribe(
				() => {
					notify("Parts Logged Successfully!", "success", 5000);
					this.getOutsideDue();
					this.showOutsideRecieved = false;
					this.showPopup = false;
				},
				(err) => {
					notify("Something went wrong!", "error", 5000);
					console.log(err);
				}
			);
	}

	outsideSent() {
		this.workOrderStageService.completedQuantityApiKey(this.selectedStage.id, 1)
			.subscribe(
				() => {
					notify("Parts Logged Successfully!", "success", 5000);
					this.getOutsideDue();
					this.showOutsideSent = false;
					this.showPopup = false;
				},
				(err) => {
					notify("Something went wrong!", "error", 5000);
					console.log(err);
				}
			);
	}

	renderMode(mode: number) {
		if (mode < 4) {
			// In
			this.direction = 0;
		} else {
			// Out
			this.direction = 1;
		}
		switch (mode) {
			case 1:
				// In - Purchase
				if (this.firstLoad == true) {
					this.firstLoad = false;
					this.getPlogs();
				}
				return "Purchase";
			case 2:
				// In Outside Process
				if (this.firstLoad == true) {
					this.firstLoad = false;
					this.getOutsideDue();
				}
				return "Outside Process";
			case 3:
				// In - Internal
				return "Internal";
			case 4:
				//  Out - OUTSIDE PROCESS
				// In Outside Process
				if (this.firstLoad == true) {
					this.firstLoad = false;
					this.getOutsideDue();
				}
				return "Suppliers";
			case 5:
				// Out - Customer
				return "Customer";
			case 6:
				// Out - Internal
				return "Internal";
			default:
				this.direction = 999;
				return "UNKNOWN";
		}
	}

	renderPlogDueDate(date: string) {
		return new Date(date).toLocaleDateString("en-GB");
	}

	renderPlogLineItems(lineItems: string) {
		const lineItemsConverted: PurchaseLogGridLineItem[] = JSON.parse(lineItems);
		return (lineItemsConverted.length);
	}

	renderPlogType(id: number) {
		return this.purchaseTypes.filter(x => x.id == id)[0].name;
	}

	renderSite(id: number) {
		switch (id) {
			case 1:
				return "KINGS NORTON";
			case 2:
				return "SELLY OAK";
			case 3:
				return "REDDITCH";
			default:
				return "KINGS NORTON";
		}
	}

	selectDeliveryNote(dn: any) {
		this.selectDeliveryNote = dn;
		this.showCustomerSent = true;
		this.showPopup = true;
	}

	selectStage(stage: any) {
		if (this.direction == 0) {
			this.selectedStage = stage;
			this.showOutsideRecieved = true;
			this.quantityEntered = stage.quantityRequired;
			this.showPopup = true;
		}
		else {
			this.selectedStage = stage;
			this.showOutsideSent = true;
			this.showPopup = true;
		}
	}

	selectSupplier(id: number) {
		this.purchaseLogs = this.purchaseLogsOrig.filter(x => x.supplierId = id);
		this.supplierSelected = true;
	}
}
<admin-header [primaryButtons]="headerPrimaryButtons" [title]="title" (eventFired)="headerButtonClick($event)"></admin-header>
<form action="/employee-page" (submit)="handleSubmit($event)">
	<dx-form action="" class="m-3" [(formData)]="formDetails" [showColonAfterLabel]="true" labelLocation="top" (onSubmit)="search" [colCount]="10">
		<dxi-item [colSpan]="10"><p>Search the knowledge base for guides and supporting documentation:</p></dxi-item>
		<dxi-item dataField="keywords" [colSpan]="9"><dxo-label [visible]="false"></dxo-label></dxi-item>
		<dxi-item itemType="button" [buttonOptions]="submitButtonOptions"></dxi-item>
	</dx-form>
</form>
<ul id="helpDocuments">
	<ng-template ngFor let-item [ngForOf]="items">
		<li>
			<a href="/admin/help/{{item.id}}">
			<em>{{item.section}}</em>
			<strong>{{item.title}}</strong>
			</a>
		</li>
	</ng-template>
</ul>
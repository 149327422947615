export enum FirewallLogFailureReason {
	// WeldIssue = 10,
	// RivetNutIssue = 20,
	// Split = 30,
	// PaintIssue = 40,
	// MissingFixing = 50,
	MissingNut = 60,
	MissingStud = 70,
	MissingWasher = 80,
	MissingSubComponent = 90,
	MissingRubberBushing = 100,
	MissingRivet = 110,
	MissingOrIncorrectLabel = 120,
	MissingSpotWeld = 130,
	MissingMigWeld = 140,
	LackOfFusion = 150,
	ExcessiveFlash = 160,
	PorosityInTheWeld = 170,
	CrackInWeld = 180,
	UndersizedNugget = 200,
	EdgeWeld = 210,
	InclusionsInTheWeld = 220,
	ExcessiveSpatter = 230,
	WeldSpike = 240,
	LackOfPenetration = 250,
	MissingPaint = 260,
	SludgeOrInclusionInPaintWork = 270,
	PittingInPaintWork = 280,
	PaintPenMarksUnderPaint = 290,
	Scratches = 300,
	PoorAdhesionOrBubbling = 310,
	ExcessiveElectrodeMarks = 320,
	RinseWaterCorrosion = 330,
	MissingHolesOrSlots = 340,
	IncorrectlyPressed = 350,
	IncorrectlyCut = 360,
	MissingOdetteLabel = 370,
	IncorrectlyFormed = 380
}
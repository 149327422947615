import { InteractionType } from "@enums/interactionType.enum";
import { Model } from "@interfaces/model.interface";

export interface ClientInteraction extends Model {
	clientContactId: number;
	clientId: number;
	contactEmailAddress: string;
	instigatorId: number;
	interactionSummary: string;
	interactionType: InteractionType;
}

export class ClientInteraction implements ClientInteraction {

	constructor(data?: Partial<ClientInteraction>) {
		if (data) {
			for (const key of Object.keys(data)) {
				(this as any)[key] = (data as any)[key];
			}
		}
	}
}
import { Injectable, Injector } from "@angular/core";
import { environment } from "@environments/environment";
import { Service } from "@services/service";

@Injectable()
export class PurchaseLogService extends Service {
	constructor(injector: Injector) {
		super("PurchaseLog", injector);
	}

	// API
	app_Complete(id:number) {
		return this.http.get(`${environment.server.apiURL}/${this.endpoint}/App_Complete/${id}`);
	}
	app_CompleteMissing(id:number) {
		return this.http.get(`${environment.server.apiURL}/${this.endpoint}/App_CompleteMissing/${id}`);
	}
	app_GetById(id: number) {
		return this.http.get(`${environment.server.apiURL}/${this.endpoint}/App_GetById/${id}`);
	}

	app_GetDue() {
		return this.http.get(`${environment.server.apiURL}/${this.endpoint}/App_GetDue/`);
	}

	duplicatePurchaseLog(id: number){
		return this.http.post(`${environment.server.apiURL}/${this.endpoint}/Duplicate/${id}`, {});
	}

	generateAndEmailPdf(id: number) {
		return this.http.get(`${environment.server.apiURL}/${this.endpoint}/GenerateAndEmailPdf/${id}`);
	}

	generateAndPreviewPdf(id: number) {
		return this.http.get(`${environment.server.apiURL}/${this.endpoint}/GenerateAndPreviewPdf/${id}`, { responseType: "blob" as "json"} );
	}
	
	getByProjectId(id: number) {
		return this.http.get(`${environment.server.apiURL}/${this.endpoint}/GetByProjectId/${id}`);
	}

	getTotalSpend(id:number) {
		return this.http.get(`${environment.server.apiURL}/${this.endpoint}/GetTotalSpend/${id}`);
	}
}
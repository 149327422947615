import { Incoterms } from "@enums/incoterms.enum";
import { SalesEnquiryStatus } from "@enums/salesEnquiryStatus.enum";
import { Model } from "@interfaces/model.interface";
import { Client } from "./client.interface";

export interface SalesEnquiry extends Model {
	approved: boolean;
	approvedById: number;
	assignedToId: number;
	client: Client
	clientContactId: number;
	clientId: number;
	closingComments: string;
	comments: string;
	contactEmail: string;
	contactName: string;
	contactPhone: string;
	createdById: number;
	customerRef: string;
	enquiryLines: number;
	enquiryQuantity: number;
	enquiryValue: number;
	externalComments: string; //Json String
	feasibilityQuestionAdditionalDetails: string;
	feasibilityQuestionAlternativeManufacturing?: boolean;
	feasibilityQuestionAreProcessesInControl?: boolean;
	feasibilityQuestionCanEngineeringPerformanceSpecsBeMet?: boolean;
	feasibilityQuestionCanProductBeManufacturedToTolerances?: boolean;
	feasibilityQuestionCanProductBeManufacturedWithProcessCapability?: boolean;
	feasibilityQuestionCorrelatePrevention?: boolean;
	feasibilityQuestionCostsForCapital?: boolean;
	feasibilityQuestionCostsForTooling?: boolean;
	feasibilityQuestionDoesCapabilityMeetCustomerRequirements?: boolean;
	feasibilityQuestionDoesDesignAllowEfficientHandling?: boolean;
	feasibilityQuestionIsAdequateCapacity?: boolean;
	feasibilityQuestionIsProductAdequatelyDefined?: boolean;
	feasibilityQuestionIsStatisticalControlRequired?: boolean;
	feasibilityQuestionIsStatisticalControlRequiredOnSimilar?: boolean;
	feasibilityQuestionnaireCompletedById?: number;
	feasibilityQuestionnaireCompletedOn?: Date;
	followUps: string;
	incoterms: Incoterms;
	lineItems: string; //Json String
	paymentTerms: number;
	pointOfContactId: number;
	productionSignedOffById?: number;
	productionSignedOffOn?: Date;
	projectId: number;
	qualitySignedOffById?: number;
	qualitySignedOffOn?: Date;
	quoteSubmitted: Date;
	quoted: boolean;
	quotedById: number;
	salesEnquiryStatus: SalesEnquiryStatus;
	success: boolean;
}

export class SalesEnquiry implements SalesEnquiry {
	approved = false;
	client = new Client();
	clientContactId = 0;
	closingComments = "";
	comments = "";
	contactEmail = "";
	contactName = "";
	contactPhone = "";
	customerRef = "";
	enquiryLines = 0;
	enquiryQuantity = 0;
	enquiryValue = 0;
	externalComments = "";
	followUps = ""; //Json String
	incoterms = Incoterms.EXW;
	lineItems = ""; //Json String
	paymentTerms = 14;
	quoted = false;
	salesEnquiryStatus = SalesEnquiryStatus.Ongoing;
	success = false;

	constructor(data?: Partial<SalesEnquiry>) {
		if (data) {
			for (const key of Object.keys(data)) {
				(this as any)[key] = (data as any)[key];
			}
		}
	}
}
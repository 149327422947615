import { Model } from "@interfaces/model.interface";

export interface HelpDocument extends Model {
	authorId: number;
	section: string;
	tags: string;
	title: string;
	url: string;
}

export class HelpDocument implements HelpDocument {
	constructor(data?: Partial<HelpDocument>) {
		if (data) {
			for (const key of Object.keys(data)) {
				(this as any)[key] = (data as any)[key];
			}
		}
	}
}
import { Component } from "@angular/core";
import { ActivatedRoute,Router } from "@angular/router";
import notify from "devextreme/ui/notify";
import { PackagingLabelSize } from "@enums/packagingLabelSize.enum";
import { AdminHeaderButton } from "@interfaces/adminHeaderButton.interface";
import { ChangeQuantity } from "@interfaces/changeQuantity.interface";
import { Client } from "@interfaces/client.interface";
import { ClientLocation } from "@interfaces/clientLocation.interface";
import { DownloadShippingNoteLabels } from "@interfaces/downloadShippingNoteLabels.interface";
import { EdiShippingNote } from "@interfaces/ediShippingNote.interface";
import { EdiShippingNoteItem } from "@interfaces/ediShippingNoteItem.interface";
import { SalesOrder } from "@interfaces/salesOrder.interface";
import { ClientService } from "@services/client.service";
import { ClientLocationService } from "@services/clientLocation.service";
import { EdiShippingNoteService } from "@services/ediShippingNote.service";
import { EdiShippingNoteItemService } from "@services/ediShippingNoteItem.service";
import { SalesOrderService } from "@services/salesOrder.service";
import { Guid } from "guid-typescript";

@Component({
	moduleId: module.id,
	selector: "ediShippingNotes-id",
	styleUrls: ["ediShippingNotes-id.css"],
	templateUrl: "ediShippingNotes-id.html"
})
export class EdiShippingNotesIdAdminComponent {
	asnEditingBlocked = true;
	client: Client = new Client();
	clientLocation: ClientLocation = new ClientLocation();
	headerPrimaryButtons: AdminHeaderButton[] = [];
	headerSecondaryButtons: AdminHeaderButton[] = [];
	headerTertiaryButtons: AdminHeaderButton[] = [];
	item: EdiShippingNote = new EdiShippingNote();
	itemType = "Shipping Note";
	labelSizes: PackagingLabelSize[] = [];
	popupAddButtonOptions: any;
	popupClient: Client = new Client();
	popupCloseButtonOptions: any = { onClick: () => this.closePopup(), text: "Close" };
	popupLineItem: EdiShippingNoteItem = new EdiShippingNoteItem();
	popupTitle = "";
	popupVisible = false;
	readOnly = true;
	returnUrl: string = location.href.split("/")[3] + "/" + location.href.split("/")[4];
	salesOrder: SalesOrder = new SalesOrder();
	shippingNoteItems: any[] = [];
	statuses: any;
	title: string = "View " + this.itemType;

	constructor(private router: Router, private route: ActivatedRoute, private itemService: EdiShippingNoteService, private clientLocationService: ClientLocationService, private clientService: ClientService, private shippingNoteItemService: EdiShippingNoteItemService, private salesOrderService: SalesOrderService) {
		this.editLineItemPopup = this.editLineItemPopup.bind(this);
		this.route.params
			.subscribe((params) => {
				if (params.id != "create") {
					if (params.id.split("_").length > 1) {
						this.getItem(params.id.split("_")[0], "edit");
						this.edit();
					} else {
						this.getItem(params.id, "view");
					}
				}
			});
		this.viewSalesOrder = this.viewSalesOrder.bind(this);
	}

	buildButtons(mode: string) {
		this.headerPrimaryButtons = [];
		this.headerSecondaryButtons = [];
		this.headerTertiaryButtons = [];	

		if (mode == "edit") {
			if (this.item.isLocked == false) {
				this.headerPrimaryButtons.push({ method: "updateItem", text: "Save Changes" });
			}
			this.headerPrimaryButtons.push({ method: "cancelEditing", text: "Cancel" });
			this.headerPrimaryButtons.push({ method: "close", text: "Close" });
		} else if (mode == "view") {
			if (this.item.isLocked === false) {
				this.headerPrimaryButtons.push({ method: "edit", text: "Edit" });
				this.headerTertiaryButtons.push({ method: "lock", text: "Lock Details" });
			} else {
				if (this.labelSizes.includes(PackagingLabelSize.Small)) {
					this.headerTertiaryButtons.push({ method: "generateSmallLabelsPDF", text: "Small Labels" });
				}
				if (this.labelSizes.includes(PackagingLabelSize.Standard)) {
					this.headerTertiaryButtons.push({ method: "generateStandardLabelsPDF", text: "Standard Labels" });
				}
				if (this.item.totalPallets >= 1) {
					this.headerTertiaryButtons.push({ method: "generateMasterLabels", text: "Pallet Labels" });
				}
				if (this.item.clientLocationId === 2) { // Location code for NITRA
					this.headerTertiaryButtons.push({ method: "generateNitraExportDocumentPDF", text: "Export Document" });
				}
				if (this.item.isSent == false) {
					if (this.client.sendASNsViaEDI === true) {
						this.headerSecondaryButtons.push({ method: "sendAsn", text: "Send ASN" });
					}
					else
					{
						this.headerSecondaryButtons.push({ method: "markSent", text: "Mark as Sent" });
					}
				}
				if (this.item.salesOrderId !== undefined && this.item.salesOrderId !== null) {
					this.headerSecondaryButtons.push({ method: "viewSalesOrder", text: "View Sales Order" });
				}
			}
			this.headerPrimaryButtons.push({ method: "close", text: "Close" });
		}
	}

	cancelEditing() {
		if (confirm("Are you sure you wish to switch modes? Unsaved changes will be discarded.") == false) {
			return;
		}
		this.readOnly = true;
		this.buildButtons("view");
		this.title = "View " + this.itemType;
	}

	close() {
		this.router.navigate([`/${this.returnUrl}/`]);
	}

	closePopup() {
		this.popupVisible = false;
	}

	edit() {
		this.readOnly = false;
		this.buildButtons("edit");
		this.title = "Edit " + this.itemType;
		notify("You are now editing...", "warning", 5000);
	}

	editLineItemPopup(e: any) {
		if (this.item === null || this.item === undefined || this.item.isLocked === true) {
			return;
		}

		this.shippingNoteItemService.getSingleById(e.row.data.id).subscribe(
			(res: any) => {
				this.popupVisible = true;
				this.popupAddButtonOptions = { onClick: () => this.updateLineItem(), text: "Save Changes" };
				this.popupCloseButtonOptions = { onClick: () => this.closePopup(), text: "Close" };
				this.popupLineItem = res.response;
				this.popupTitle = "Edit Line Item";
			},
			(err) => {
				console.log(err);
				notify("Could not edit line item", "error", 5000);
			}
		);
	}

	generateLabelsPDF(labelSize: PackagingLabelSize) {
		const values = new DownloadShippingNoteLabels();
		values.shippingNoteId = this.item.id;
		values.labelSize = labelSize;
		this.itemService.generateLabels(values).subscribe(
			(response: any) =>{
				const dataType = response.type;
				const binaryData = [];
				binaryData.push(response);
				const downloadLink = document.createElement("a");
				downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, {type: dataType}));
				downloadLink.setAttribute("download", "ShippingNote_" + this.item.id + "_" + PackagingLabelSize[labelSize] + "_Labels.pdf");
				document.body.appendChild(downloadLink);
				downloadLink.click();
			}
		);
	}

	generateMasterLabels(){
		this.generateMasterLabelsPDF();
	}

	generateMasterLabelsPDF() {
		this.itemService.generateMasterLabels(this.item.id).subscribe(
			(response: any) =>{
				const dataType = response.type;
				const binaryData = [];
				binaryData.push(response);
				const downloadLink = document.createElement("a");
				downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, {type: dataType}));
				downloadLink.setAttribute("download", "ShippingNotePallets_" + this.item.id + "_Labels.pdf");
				document.body.appendChild(downloadLink);
				downloadLink.click();
			}
		);
	}

	generateNitraExportDocumentPDF() {
		this.itemService.generateNitraExportDocument(this.item.id).subscribe(
			(response: any) =>{
				const dataType = response.type;
				const binaryData = [];
				binaryData.push(response);
				const downloadLink = document.createElement("a");
				downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, {type: dataType}));
				downloadLink.setAttribute("download", "ShippingNotePallets_" + this.item.id + "_Export_Document.pdf");
				document.body.appendChild(downloadLink);
				downloadLink.click();
			}
		);
	}

	generateSmallLabelsPDF() {
		this.generateLabelsPDF(PackagingLabelSize.Small);
	}

	generateStandardLabelsPDF() {
		this.generateLabelsPDF(PackagingLabelSize.Standard);
	}

	getClient(id: number, buttonMode: string) {
		this.clientService.getSingleById(id)
			.subscribe(
				(res: any) => {
					this.client = res.response;
					this.asnEditingBlocked = this.client.sendASNsViaEDI;
					if (this.item.isLocked) {
						this.getLabelSizes(this.item.id);
					} else {
						this.buildButtons(buttonMode);
					}
				},
				(err) => {console.log(err); notify("Something Went Wrong!", "Error", 5000);}
			);
	}

	getClientLocation(id: number, buttonMode: string) {
		this.clientLocationService.getSingleById(id)
			.subscribe(
				(res: any) => {
					this.clientLocation = res.response;
					this.getClient(this.clientLocation.clientId, buttonMode);
				},
				(err) => {console.log(err); notify("Something Went Wrong!", "Error", 5000);}
			);
	}

	getItem(itemId: Guid, buttonMode: string) {
		this.itemService.getSingleByLookupId(itemId)
			.subscribe(
				(res: any) => {
					this.item = res.response;
					this.getSalesOrder();
					this.getShippingNoteItems(this.item.id);
					this.getClientLocation(this.item.clientLocationId, buttonMode);
				},
				(err) => {console.log(err); notify("Something Went Wrong!", "Error", 5000);}
			);
	}

	getLabelSizes(id: number) {
		this.itemService.getLabelSizes(id)
			.subscribe(
				(res: any) => {
					this.labelSizes = res.response;
					this.buildButtons("view");
				},
				(err) => {console.log(err); notify("Something Went Wrong!", "Error", 5000);}
			);
	}

	getSalesOrder() {
		if (this.item.salesOrderId === null || this.item.salesOrderId === undefined) {
			return;
		}
		this.salesOrderService.getSingleById(this.item.salesOrderId)
			.subscribe(
				(res: any) => {
					this.salesOrder = res.response;
				},
				(err) => {console.log(err); notify("Something Went Wrong!", "Error", 5000);}
			);
	}

	getShippingNoteItems(id: number) {
		this.shippingNoteItemService.getItemsForShippingNote(id)
			.subscribe(
				(res: any) => {
					this.shippingNoteItems = res.response;
				},
				(err) => {console.log(err); notify("Something Went Wrong!", "Error", 5000);}
			);
	}

	headerButtonClick(method: any) {
		// @ts-ignore // Required to be able to call the method directly from the variable
		if (this[method]) this[method]();
	}

	lock() {
		if (confirm("Are you sure you wish to lock this shipping note and generate the ASN Number? You will be unable to make further changes.") == false) {
			return;
		}

		this.itemService.lockShippingNote(this.item.id)
			.subscribe(
				() => {
					notify("Shipping Note Locked", "Success", 5000);
					this.getItem(this.item.lookupId, "view");
				},
				(err) => {console.log(err); notify("Something Went Wrong!", "Error", 5000);}
			);
	}

	markSent() {
		if (confirm("Are you sure you wish to mark this shipping note as sent? This cannot be undone.") == false) {
			return;
		}
		
		this.itemService.markSent(this.item.id)
			.subscribe(
				() => {
					notify("Marked as sent", "Success", 5000);
					this.getItem(this.item.lookupId, "view");
				},
				(err) => {console.log(err); notify("Something Went Wrong!", "Error", 5000);}
			);
	}
	
	onLineItemRowPrepared(e: any) {
		if (e.rowType === "data") {  
			if (e.data.quantity !== e.data.ediOrder.orderedQuantity) {
				e.rowElement.classList.add("quantityMisMatch");
				e.rowElement.style.backgroundColor = "#ffd2ad";  
				e.rowElement.className = e.rowElement.className.replace("dx-row-alt", "");
			}
		}  
	}

	sendAsn() {
		if (confirm("Are you sure you wish to generate and send the ASN for this shipping note? This cannot be undone.") == false) {
			return;
		}
		
		this.itemService.sendMessage(this.item.id)
			.subscribe(
				() => {
					notify("Message Queued", "Success", 5000);
					this.getItem(this.item.lookupId, "view");
				},
				(err) => {console.log(err); notify("Something Went Wrong!", "Error", 5000);}
			);
	}

	updateItem() {
		if (this.item.isLocked) {
			notify("Cannot change locked Shipping Notes", "error", 5000);
			return;
		}

		this.itemService.updateSingleById(this.item.id, this.item)
			.subscribe(
				() => {
					notify("Successfully Updated " + this.itemType, "success", 5000);
					this.cancelEditing();
				},
				(err) => {
					console.log(err);
					notify("Something went wrong!", "error", 5000);
				}
			);
	}

	updateLineItem() {
		if (this.item === null || this.item === undefined || this.item.isLocked === true) {
			return;
		}

		const newQuantity = new ChangeQuantity();
		newQuantity.quantity = this.popupLineItem.quantity;

		this.shippingNoteItemService.changeQuantity(this.popupLineItem.id, newQuantity)
			.subscribe(
				() => {
					notify("Successfully Updated Line Item", "success", 5000);					
					this.closePopup();
					this.getShippingNoteItems(this.item.id);
				},
				(err) => {
					console.log(err);
					notify("Something went wrong!", "error", 5000);
				}
			);
	}

	viewSalesOrder() {
		this.router.navigate([`admin/salesOrders/${this.salesOrder.lookupId}`]);
	}
}
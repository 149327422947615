<div class="operator-bg" style="width:100vw; height:100vh;">
	<div class="row" style="width:50vw; background-color: #346982; margin-left:0.5vw">
		<h2 style="color:white; margin-top: 15px; margin-left:15px; margin-bottom: 15px;">// STOCK SCAN INTERFACE</h2>
	</div>
	<div class="row" style="margin-left:0.5vw">
		<select id="deviceId" #Id="ngModel" class="hideLabel form-control" [(ngModel)]="selectedDevice" name="label" aria-placeholder="Select Camera" style="width:50vw;border-radius:0px; height:100px; font-size: 40px;" (change)="onDeviceSelectChange();">	 
		 <option [ngValue]="k.deviceId" *ngFor="let k of availableDevices">  
			 {{k.label}}  
	  </option>  
	</select>  
	</div>
<zxing-scanner
  #scanner
  [formats]="[allowedFormats]"
  [device]="selectedDevice"
  (camerasFound)="cameraFound($event)"
  (camerasNotFound)="camerasNotFound()"
  (scanSuccess)="onScanSuccess($event)"
  style="width:50vw !important; margin-left:0.5vw;"
>
</zxing-scanner>
<!-- <div *ngIf="availableDevices">
    <select (change)="onDeviceSelectChange(event.target.value)">
        <option value="" [selected]="!selectedDevice">No Device</option>
        <option *ngFor="let device of availableDevices" [value]="device.deviceId" [selected]="selectedDevice && device.deviceId === selectedDevice.deviceId">{{ device.label }}</option>
    </select>
</div> -->
<admin-header [primaryButtons]="headerPrimaryButtons" [secondaryButtons]="headerSecondaryButtons" [tertiaryButtons]="headerTertiaryButtons" [title]="title" (eventFired)="headerButtonClick($event)"></admin-header>
<div *ngIf="item.feasibilityQuestionnaireCompletedOn === null" class="badNews">Feasibility Checks have not been completed for this quote.</div>
<div *ngIf="item.feasibilityQuestionnaireCompletedOn !== null && (item.productionSignedOffOn === null || item.qualitySignedOffOn === null)" class="badNews">Feasibility completed, sign off required</div>
<div *ngIf="item.feasibilityQuestionnaireCompletedOn !== null && item.productionSignedOffOn !== null && item.qualitySignedOffOn !== null" class="greatNews">Feasibility completed and signed off</div>
<div class="form-container">
	<dx-form *ngIf="showForm" class="m-3" [(formData)]="item" [showColonAfterLabel]="true" labelLocation="top" [readOnly]="readOnly" (onFieldDataChanged)="formDataChanged($event)">
		<dxi-item itemType="group" caption="Details" [colCount]="6">
			<dxi-item [colSpan]="3" dataField="clientId" editorType="dxSelectBox" [editorOptions]="{ items: clients, searchEnabled: true, displayExpr:'name', valueExpr:'id' }" [label]="{ text: 'Client' }"></dxi-item>
			<dxi-item [colSpan]="3" dataField="clientContactId" editorType="dxSelectBox" [editorOptions]="{ items: contacts, searchEnabled: true, displayExpr: 'displayName', valueExpr:'id' }" [label]="{ text: 'Contact' }"></dxi-item>
			<dxi-item [colSpan]="3" dataField="contactName" [editorOptions]="{ readOnly: true }"></dxi-item>
			<dxi-item [colSpan]="3" dataField="contactEmail" [editorOptions]="{ readOnly: true }"></dxi-item>
			<dxi-item [colSpan]="3" dataField="customerRef"></dxi-item>
			<dxi-item [colSpan]="3" dataField="projectId" editorType="dxSelectBox" [editorOptions]="{ items: projects, searchEnabled: true, displayExpr:'displayName', valueExpr:'id' }" [label]="{ text: 'Project' }"></dxi-item>
			<dxi-item [colSpan]="3" dataField="assignedToId" editorType="dxSelectBox" [editorOptions]="{ items: people, searchEnabled: true, displayExpr:'fullName', valueExpr:'id' }" [label]="{ text: 'Assigned To' }"></dxi-item>
			<dxi-item [colSpan]="3" dataField="pointOfContactId" editorType="dxSelectBox" [editorOptions]="{ items: people, searchEnabled: true, displayExpr:'fullName', valueExpr:'id' }" [label]="{ text: 'Point of Contact' }"></dxi-item>
			<dxi-item [colSpan]="3" dataField="quotedById" editorType="dxSelectBox" [editorOptions]="{ items: people, searchEnabled: true, displayExpr:'fullName', valueExpr:'id', readOnly: true }" [label]="{ text: 'Quoted By' }"></dxi-item>
			<dxi-item [colSpan]="1" dataField="quoted" [editorOptions]="{ readOnly: true }"></dxi-item>
			<dxi-item [colSpan]="2" dataField="quoteSubmitted" [editorOptions]="{ readOnly: true }"></dxi-item>
			<dxi-item [colSpan]="2" dataField="approved" [editorOptions]="{ readOnly: true }"></dxi-item>
			<dxi-item [colSpan]="4" dataField="approvedById" editorType="dxSelectBox" [editorOptions]="{ items: people, searchEnabled: true, displayExpr:'fullName', valueExpr:'id', readOnly: true }" [label]="{ text: 'Approved By' }"></dxi-item>
			<dxi-item [colSpan]="2" dataField="enquiryLines" [editorOptions]="{ readOnly: true }"></dxi-item>
			<dxi-item [colSpan]="2" dataField="enquiryQuantity" [editorOptions]="{ readOnly: true }"></dxi-item>
			<dxi-item [colSpan]="2" dataField="enquiryValue" [editorOptions]="{ readOnly: true, format: {type:'currency', currency:'GBP', precision: 2} }"></dxi-item>
			<dxi-item [colSpan]="3" dataField="incoterms" editorType="dxSelectBox" [editorOptions]="{ items: incoterms, searchEnabled: true, displayExpr:'name', valueExpr:'id' }" [label]="{ text: 'Incoterms' }"></dxi-item>
			<dxi-item [colSpan]="3" dataField="paymentTerms" [label]="{text: 'Payment Terms (0 for Pro Forma)'}"></dxi-item>
			<dxi-item [colSpan]="3" dataField="salesEnquiryStatus" editorType="dxSelectBox" [editorOptions]="{ items: statuses, displayExpr:'name', valueExpr:'id', readOnly: true }" [label]="{ text: 'Status' }"></dxi-item>
			<dxi-item [colSpan]="3" dataField="comments" [label]="{text: 'Internal System Comments'}"></dxi-item>
			<dxi-item [colSpan]="3" dataField="externalComments" [label]="{text: 'External Comments (Customer Visible)'}"></dxi-item>
			<dxi-item [colSpan]="3" dataField="closingComments" [editorOptions]="{ readOnly: true }"></dxi-item>
			<dxi-item [colSpan]="3" dataField="feasibilityQuestionnaireCompletedOn" editorType="dxDateBox" [editorOptions]="{ readOnly: true, displayFormat: 'dd/MM/yyyy', type: 'date' } " [label]="{ text: 'Sales Feasibility Signed Off On' }"></dxi-item>
			<dxi-item [colSpan]="3" dataField="feasibilityQuestionnaireCompletedById" editorType="dxSelectBox" [editorOptions]="{ items: people, searchEnabled: true, displayExpr:'fullName', valueExpr:'id', readOnly: true }" [label]="{ text: 'Sales Feasibility Signed Off By' }"></dxi-item>
			<dxi-item [colSpan]="3" dataField="qualitySignedOffOn" editorType="dxDateBox" [editorOptions]="{ readOnly: true, displayFormat: 'dd/MM/yyyy', type: 'date' } " [label]="{ text: 'Quality Feasibility Signed Off On' }"></dxi-item>
			<dxi-item [colSpan]="3" dataField="qualitySignedOffById" editorType="dxSelectBox" [editorOptions]="{ items: people, searchEnabled: true, displayExpr:'fullName', valueExpr:'id', readOnly: true }" [label]="{ text: 'Quality Feasibility Signed Off By' }"></dxi-item>
			<dxi-item [colSpan]="3" dataField="productionSignedOffOn"  editorType="dxDateBox" [editorOptions]="{ readOnly: true, displayFormat: 'dd/MM/yyyy', type: 'date'  }" [label]="{ text: 'Production Feasibility Signed Off On' }"></dxi-item>
			<dxi-item [colSpan]="3" dataField="productionSignedOffById" editorType="dxSelectBox" [editorOptions]="{ items: people, searchEnabled: true, displayExpr:'fullName', valueExpr:'id', readOnly: true }" [label]="{ text: 'Production Feasibility Signed Off By' }"></dxi-item>
		</dxi-item>
		<dxi-item itemType="group" caption="Line Items" *ngIf="title.includes('Create')==false">
			<dx-data-grid #gridContainer class="data-grid" [dataSource]="lineItems" [remoteOperations]="false" [allowColumnReordering]="false" [allowColumnResizing]="true" [rowAlternationEnabled]="true" [showBorders]="true" (onRowInserted)="updateItem()" (onRowUpdated)="updateItem()" (onRowRemoved)="updateItem()">
				<dxo-paging [pageSize]="100"></dxo-paging>
				<dxo-state-storing [enabled]="true" type="localStorage" storageKey="salesLines"></dxo-state-storing>
				<dxo-pager [visible]="false" [showPageSizeSelector]="false" [allowedPageSizes]="[10, 25, 50, 100]"></dxo-pager>
				<dxo-search-panel [visible]="false" [highlightCaseSensitive]="true"></dxo-search-panel>
				<dxo-group-panel [visible]="false"></dxo-group-panel>
				<dxo-grouping [autoExpandAll]="false"></dxo-grouping>
				<dxo-export [enabled]="false"></dxo-export>
				<dxo-editing mode="popup" [allowAdding]="!readOnly" [allowDeleting]="!readOnly" [allowUpdating]="true" [confirmDelete]="true" [useIcons]="true" newRowPosition="last" [changes]="changes" [(editRowKey)]="editRowKey">
					<dxo-popup title="Line Item Details" [showTitle]="true" [width]="'70%'" [height]="'auto'"></dxo-popup>
					<dxo-form [readOnly]="readOnly">
						<dxi-item itemType="group" [colCount]="2" [colSpan]="2" caption="Basic Details">
							<dxi-item [colSpan]="1" dataField="partNo" [editorOptions]="{ readOnly: readOnly }"></dxi-item>
							<dxi-item [colSpan]="1" dataField="drawing" editorType="dxSelectBox" [editorOptions]="{ items: cadQuoteLevels, searchEnabled: true, displayExpr:'name', valueExpr:'id', readOnly: readOnly }"></dxi-item>
							<dxi-item [colSpan]="2" dataField="materialSpec" [editorOptions]="{ readOnly: readOnly }"></dxi-item>
							<dxi-item dataField="quantity" [editorOptions]="{ format : '#0', readOnly: readOnly }"></dxi-item>
							<dxi-item dataField="minimumOrderQuantity" [editorOptions]="{ format : '#0', readOnly: readOnly }"></dxi-item>
							<dxi-item dataField="quantityPerAnnum" [editorOptions]="{ format : '#0', readOnly: readOnly }"></dxi-item>
							<dxi-item dataField="unitCost" [editorOptions]="{ readOnly: readOnly, format: {type:'currency', currency:'GBP', precision: 4} }"></dxi-item>
						</dxi-item>
						<dxi-item id="bookingsTab" itemType="tabbed" colSpan="2" [tabPanelOptions]="{hoverStateEnabled:false}">
							<dxo-tab-panel-options [deferRendering]="false" height="215"></dxo-tab-panel-options>
							<dxi-tab title="Additional" [colCount]="2" height="215">
								<dxi-item dataField="tooling" [editorOptions]="{ readOnly: readOnly, format: {type:'currency', currency:'GBP', precision: 2} }"></dxi-item>
								<dxi-item dataField="inspectionFixture" [editorOptions]="{ readOnly: readOnly, format: {type:'currency', currency:'GBP', precision: 2} }"></dxi-item>
								<dxi-item dataField="inspectionType" editorType="dxSelectBox" [editorOptions]="{ items: inspectionTypes, searchEnabled: true, displayExpr:'name', valueExpr:'id', readOnly: readOnly }"></dxi-item>
								<dxi-item dataField="laserFixture" [editorOptions]="{ readOnly: readOnly, format: {type:'currency', currency:'GBP', precision: 2} }"></dxi-item>
								<dxi-item dataField="weldFixture" [editorOptions]="{ readOnly: readOnly, format: {type:'currency', currency:'GBP', precision: 2} }"></dxi-item>
								<dxi-item dataField="assemblyFixture" [editorOptions]="{ readOnly: readOnly, format: {type:'currency', currency:'GBP', precision: 2} }"></dxi-item>
							</dxi-tab>
							<dxi-tab title="Delivery" [colCount]="2" height="215">
								<dxi-item dataField="deliveryOutsourced" [editorOptions]="{ items: deliveryOutsourcedOptions, readOnly: readOnly }"></dxi-item>
								<dxi-item dataField="deliveryCost" [editorOptions]="{ readOnly: readOnly, format: {type:'currency', currency:'GBP', precision: 2} }"></dxi-item>
								<dxi-item [colSpan]="2" dataField="leadTime" [editorOptions]="{ readOnly: readOnly }"></dxi-item>
							</dxi-tab>
							<dxi-tab title="CAD" [colCount]="2" height="215">
								<dxi-item dataField="cadName" [editorOptions]="{ items: deliveryOutsourcedOptions, readOnly: readOnly }" [label]="{ text: 'CAD Name' }"></dxi-item>
								<dxi-item dataField="cadRevision" [editorOptions]="{ items: deliveryOutsourcedOptions, readOnly: readOnly }" [label]="{ text: 'CAD Revision' }"></dxi-item>
							</dxi-tab>
						</dxi-item>
					</dxo-form>
				</dxo-editing>
				<dxo-toolbar>
					<dxi-item name="addRowButton" showText="always"></dxi-item>
				</dxo-toolbar>
				<dxi-column dataField="partNo" dataType="text" alignment="left" caption="Part Number">
					<dxi-validation-rule type="required"></dxi-validation-rule>
				</dxi-column>
				<dxi-column dataField="drawing" dataType="number" alignment="left" caption="Drawing / CAD" [visible]="false">
					<dxo-lookup [dataSource]="cadQuoteLevels" displayExpr="name" valueExpr="id"></dxo-lookup>
					<dxi-validation-rule type="required"></dxi-validation-rule>
				</dxi-column>
				<dxi-column dataField="materialSpec" dataType="text" alignment="left">
				</dxi-column>
				<dxi-column dataField="quantity" dataType="number" alignment="left" caption="QTY">
					<dxi-validation-rule type="required"></dxi-validation-rule>
				</dxi-column>
				<dxi-column dataField="minimumOrderQuantity" dataType="number" alignment="left" caption="MOQ"></dxi-column>
				<dxi-column dataField="quantityPerAnnum" dataType="number" alignment="left" caption="QPA"></dxi-column>
				<dxi-column dataField="unitCost" dataType="number" alignment="left" caption="Unit Cost" [format]="{ style: 'currency', currency: 'GBP', useGrouping: true, precision: 4, type: 'currency' }">
					<dxi-validation-rule type="required"></dxi-validation-rule>
				</dxi-column>
				<dxi-column dataField="tooling" dataType="number" alignment="left" caption="Tooling" [format]="{ style: 'currency', currency: 'GBP', useGrouping: true, precision: 2, type: 'currency' }">
					<dxi-validation-rule type="required"></dxi-validation-rule>
				</dxi-column>
				<dxi-column dataField="inspectionFixture" dataType="number" alignment="left" caption="Inspection Fixture" [format]="{ style: 'currency', currency: 'GBP', useGrouping: true, precision: 2, type: 'currency' }">
					<dxi-validation-rule type="required"></dxi-validation-rule>
				</dxi-column>
				<dxi-column dataField="inspectionType" dataType="number" alignment="left" caption="Inspection Type" [visible]="false">
					<dxo-lookup [dataSource]="inspectionTypes" displayExpr="name" valueExpr="id"></dxo-lookup>
					<dxi-validation-rule type="required"></dxi-validation-rule>
				</dxi-column>
				<dxi-column dataField="laserFixture" dataType="number" alignment="left" caption="Laser Fixture" [format]="{ style: 'currency', currency: 'GBP', useGrouping: true, precision: 2, type: 'currency' }">
					<dxi-validation-rule type="required"></dxi-validation-rule>
				</dxi-column>
				<dxi-column dataField="weldFixture" dataType="number" alignment="left" caption="Weld Fixture" [format]="{ style: 'currency', currency: 'GBP', useGrouping: true, precision: 2, type: 'currency' }">
					<dxi-validation-rule type="required"></dxi-validation-rule>
				</dxi-column>
				<dxi-column dataField="assemblyFixture" dataType="number" alignment="left" caption="Assembly Fixture" [format]="{ style: 'currency', currency: 'GBP', useGrouping: true, precision: 2, type: 'currency' }">
					<dxi-validation-rule type="required"></dxi-validation-rule>
				</dxi-column>
				<dxi-column dataField="deliveryOutsourced" dataType="text" alignment="left" caption="Is Delivery Outsourced" [visible]="false">
					<dxo-lookup [dataSource]="deliveryOutsourcedOptions"></dxo-lookup>
					<dxi-validation-rule type="required"></dxi-validation-rule>
				</dxi-column>
				<dxi-column dataField="deliveryCost" dataType="number" alignment="left" [visible]="false" [format]="{ style: 'currency', currency: 'GBP', useGrouping: true, precision: 2, type: 'currency' }">
					<dxi-validation-rule type="required"></dxi-validation-rule>
				</dxi-column>
				<dxi-column dataField="leadTime" dataType="text" alignment="left" caption="Lead Time">
					<dxi-validation-rule type="required"></dxi-validation-rule>
				</dxi-column>
				<dxi-column dataField="lineCost" dataType="number" alignment="left" [allowEditing]="false" [calculateCellValue]="calculateCellValue" [format]="{ style: 'currency', currency: 'GBP', useGrouping: true, precision: 4, type: 'currency' }"></dxi-column>
				<dxi-column dataField="cadName" dataType="text" alignment="left" caption="CAD Name" [visible]="false"></dxi-column>
				<dxi-column dataField="cadRevision" dataType="text" alignment="left" caption="CAD Revision" [visible]="false"></dxi-column>
				<dxi-column dataField="cadDateReceived" dataType="text" alignment="left" caption="CAD Date Received" [visible]="false"></dxi-column>
				<dxi-column type="buttons">
					<dxi-button name="edit"></dxi-button>
					<dxi-button hint="Clone" icon="copy" [onClick]="cloneIconClick"></dxi-button>
					<dxi-button name="delete"></dxi-button>
				</dxi-column>
			</dx-data-grid>
		</dxi-item>
		<dxi-item itemType="group" caption="Follow Ups" *ngIf="title.includes('Create')==false">
			<dx-data-grid #gridContainer class="data-grid" [dataSource]="followUps" [remoteOperations]="false" [allowColumnReordering]="false" [rowAlternationEnabled]="true" [showBorders]="true" (onInitNewRow)="setNewFollowUpUserId($event)" (onRowInserted)="updateItem()" (onRowUpdated)="updateItem()" (onRowRemoved)="updateItem()">
				<dxo-paging [pageSize]="100"></dxo-paging>
				<dxo-state-storing [enabled]="true" type="localStorage" storageKey="salesFollowups"></dxo-state-storing>
				<dxo-pager [visible]="false" [showPageSizeSelector]="false" [allowedPageSizes]="[10, 25, 50, 100]"></dxo-pager>
				<dxo-search-panel [visible]="false" [highlightCaseSensitive]="true"></dxo-search-panel>
				<dxo-group-panel [visible]="false"></dxo-group-panel>
				<dxo-grouping [autoExpandAll]="false"></dxo-grouping>
				<dxo-export [enabled]="false"></dxo-export>
				<dxo-editing mode="popup" [allowAdding]="!readOnly" [allowDeleting]="!readOnly" [allowUpdating]="!readOnly" [confirmDelete]="true" [useIcons]="true" newRowPosition="last" [changes]="changes" [(editRowKey)]="editRowKey">
					<dxo-popup title="Line Item Details" [showTitle]="true" [width]="'75%'" [height]="'auto'"></dxo-popup>
					<dxo-form [readOnly]="readOnly">
						<dxi-item dataField="followUpDate" editorType="dxDateBox" [editorOptions]="{ displayFormat: 'dd/MM/yyyy HH:mm:ss', type: 'datetime' }"><dxi-validation-rule type="required"></dxi-validation-rule></dxi-item>
						<dxi-item dataField="followUpType" editorType="dxSelectBox" [editorOptions]="{ items: followUpTypes, searchEnabled: true, displayExpr:'name', valueExpr:'id', readOnly: readOnly }"><dxi-validation-rule type="required"></dxi-validation-rule></dxi-item>
						<dxi-item dataField="comment" [colSpan]="2"><dxi-validation-rule type="required"></dxi-validation-rule></dxi-item>
					</dxo-form>
				</dxo-editing>
				<dxo-toolbar>
					<dxi-item name="addRowButton" showText="always"></dxi-item>
				</dxo-toolbar>
				<dxi-column dataField="userId" dataType="text" alignment="left" caption="User">
					<dxo-lookup [dataSource]="people" displayExpr="fullName" valueExpr="userId"></dxo-lookup>
				</dxi-column>
				<dxi-column dataField="followUpDate" dataType="datetime" format="dd/MM/yyyy HH:mm:ss" alignment="left" caption="Date"></dxi-column>
				<dxi-column dataField="followUpType" dataType="number" alignment="left" caption="Type">
					<dxo-lookup [dataSource]="followUpTypes" displayExpr="name" valueExpr="id"></dxo-lookup>
				</dxi-column>
				<dxi-column dataField="comment" dataType="text" alignment="left"></dxi-column>
				<dxi-column type="buttons">
					<dxi-button name="edit"></dxi-button>
					<dxi-button name="delete"></dxi-button>
				</dxi-column>
			</dx-data-grid>
		</dxi-item>
	</dx-form>
	<dx-popup [width]="'75%'" [height]="750" [showTitle]="true" [title]="popupTitle" [dragEnabled]="false" [closeOnOutsideClick]="false" [showCloseButton]="false" container=".dx-viewport" [(visible)]="popupVisible">
		<dxi-toolbar-item widget="dxButton" toolbar="bottom" location="before" [options]="popupSaveButtonOptions"></dxi-toolbar-item>
		<dxi-toolbar-item widget="dxButton" toolbar="bottom" location="after" [options]="popupCloseButtonOptions"></dxi-toolbar-item>
		<div *dxTemplate="let data of 'content'">
			<div *ngIf="popupTitle == 'Add New Client'">
				<dx-form class="m-3" [(formData)]="popupClient" [showColonAfterLabel]="true" labelLocation="top" [colCount]="2">
					<dxi-item dataField="name" [colSpan]="2" [label]="{ text: 'Client Name' }">
						<dxi-validation-rule type="required" message="Client Name is required"></dxi-validation-rule>
					</dxi-item>
					<dxi-item dataField="accountingNumber"></dxi-item>
					<dxi-item dataField="telephoneNumber" [label]="{ text: 'Contact Phone Number' }"></dxi-item>
					<dxi-item dataField="address1" [label]="{ text: 'Address Line 1' }"></dxi-item>
					<dxi-item dataField="address2" [label]="{ text: 'Address Line 2' }"></dxi-item>
					<dxi-item dataField="town"></dxi-item>
					<dxi-item dataField="postcode"></dxi-item>
					<dxi-item dataField="paymentTerms" [label]="{text: 'Payment Terms (0 for Pro Forma)'}"></dxi-item>
				</dx-form>
			</div>
			<div *ngIf="popupTitle == 'Add New Contact'">
				<dx-form class="m-3" [(formData)]="popupContact" [showColonAfterLabel]="true" labelLocation="top" [colCount]="2">
					<dxi-item dataField="contactName">
						<dxi-validation-rule type="required" message="Contact Name is required"></dxi-validation-rule>
					</dxi-item>
					<dxi-item dataField="contactEmailAddress">
						<dxi-validation-rule type="required" message="Contact Email is required"></dxi-validation-rule>
					</dxi-item>
					<dxi-item dataField="jobTitle"></dxi-item>
				</dx-form>
			</div>
			<div *ngIf="popupTitle == 'Add New Project'">
				<dx-form class="m-3" [(formData)]="popupProject" [showColonAfterLabel]="true" labelLocation="top">
					<dxi-item dataField="clientId" editorType="dxSelectBox" [editorOptions]="{ items: clients, searchEnabled: true, displayExpr:'name', valueExpr:'id' }" [label]="{ text: 'Client' }"></dxi-item>
					<dxi-item dataField="name">
						<dxi-validation-rule type="required" message="Name is required"></dxi-validation-rule>
					</dxi-item>
					<dxi-item dataField="description"></dxi-item>
				</dx-form>
			</div>
			<div *ngIf="popupTitle == 'Close Sales Enquiry'">
				<dx-form class="m-3" [(formData)]="popupCloseSalesEnquiryDetails" [showColonAfterLabel]="true" labelLocation="top">
					<dxi-item dataField="newStatus" editorType="dxSelectBox" [editorOptions]="{ items: closingStatuses, searchEnabled: true, displayExpr:'name', valueExpr:'id' }" [label]="{ text: 'Status' }">
						<dxi-validation-rule type="required" message="Status is required"></dxi-validation-rule>
					</dxi-item>
					<dxi-item dataField="closingComments">
						<dxi-validation-rule type="required" message="Closing Comments is required"></dxi-validation-rule>
					</dxi-item>
				</dx-form>
			</div>
			<div *ngIf="popupTitle == 'Complete Feasibility Checklist' || popupTitle == 'View Feasibility'">
				<dx-form class="m-3 feasibilityPopupForm" [(formData)]="item" [showColonAfterLabel]="true" labelLocation="top" [colCount]="2" [readOnly]="item.feasibilityQuestionnaireCompletedOn != null">
					<dxi-item [colSpan]="2">
						<p>All answers are required. If you answer No to any question, Additional Comments are required.</p>
					</dxi-item>
					<dxi-item itemType="group">
						<dxi-item dataField="feasibilityQuestionIsProductAdequatelyDefined" editorType="dxSelectBox" [editorOptions]="{ items: yesNoDropdown, searchEnabled: false, displayExpr:'name', valueExpr:'id', showClearButton: true }" [label]="{ text: 'Is product adequately defined (application requirements, etc. to enable feasibility evaluation?)' }"></dxi-item>
						<dxi-item dataField="feasibilityQuestionCanEngineeringPerformanceSpecsBeMet" editorType="dxSelectBox" [editorOptions]="{ items: yesNoDropdown, searchEnabled: false, displayExpr:'name', valueExpr:'id', showClearButton: true }" [label]="{ text: 'Can Engineering Performance Specifications be met as written?' }"></dxi-item>
						<dxi-item dataField="feasibilityQuestionCanProductBeManufacturedToTolerances" editorType="dxSelectBox" [editorOptions]="{ items: yesNoDropdown, searchEnabled: false, displayExpr:'name', valueExpr:'id', showClearButton: true }" [label]="{ text: 'Can product be manufactured with process capability that meet requirements?' }"></dxi-item>
						<dxi-item dataField="feasibilityQuestionCanProductBeManufacturedWithProcessCapability" editorType="dxSelectBox" [editorOptions]="{ items: yesNoDropdown, searchEnabled: false, displayExpr:'name', valueExpr:'id', showClearButton: true }" [label]="{ text: 'Is product adequately defined (application requirements, etc. to enable feasibility evaluation?)' }"></dxi-item>
						<dxi-item dataField="feasibilityQuestionCorrelatePrevention" editorType="dxSelectBox" [editorOptions]="{ items: yesNoDropdown, searchEnabled: false, displayExpr:'name', valueExpr:'id', showClearButton: true }" [label]="{ text: 'Correlate prevention to CCs SCs & HICs to determine effectiveness of the method (PFMEA)' }"></dxi-item>
						<dxi-item dataField="feasibilityQuestionIsAdequateCapacity" editorType="dxSelectBox" [editorOptions]="{ items: yesNoDropdown, searchEnabled: false, displayExpr:'name', valueExpr:'id', showClearButton: true }" [label]="{ text: 'Is there adequate capacity to produce product?' }"></dxi-item>
						<dxi-item dataField="feasibilityQuestionDoesDesignAllowEfficientHandling" editorType="dxSelectBox" [editorOptions]="{ items: yesNoDropdown, searchEnabled: false, displayExpr:'name', valueExpr:'id', showClearButton: true }" [label]="{ text: 'Does the design allow the use of efficient material handling techniques?' }"></dxi-item>
						<dxi-item dataField="feasibilityQuestionIsStatisticalControlRequired" editorType="dxSelectBox" [editorOptions]="{ items: yesNoDropdown, searchEnabled: false, displayExpr:'name', valueExpr:'id', showClearButton: true }" [label]="{ text: 'Is statistical process control required on the product?' }"></dxi-item>
						<dxi-item dataField="feasibilityQuestionIsStatisticalControlRequiredOnSimilar" editorType="dxSelectBox" [editorOptions]="{ items: yesNoDropdown, searchEnabled: false, displayExpr:'name', valueExpr:'id', showClearButton: true }" [label]="{ text: 'Is statistical process control presently used on similar products?' }"></dxi-item>
					</dxi-item>
					<dxi-item itemType="group">
						<dxi-item itemType="group" caption="Can the product be manufactured within normal cost parameters? Abnormal cost considerations may include:">
							<dxi-item dataField="feasibilityQuestionCostsForCapital" editorType="dxSelectBox" [editorOptions]="{ items: yesNoDropdown, searchEnabled: false, displayExpr:'name', valueExpr:'id', showClearButton: true }" [label]="{ text: 'Costs for capital equipment?' }"></dxi-item>
							<dxi-item dataField="feasibilityQuestionCostsForTooling" editorType="dxSelectBox" [editorOptions]="{ items: yesNoDropdown, searchEnabled: false, displayExpr:'name', valueExpr:'id', showClearButton: true }" [label]="{ text: 'Costs for tooling?' }"></dxi-item>
							<dxi-item dataField="feasibilityQuestionAlternativeManufacturing" editorType="dxSelectBox" [editorOptions]="{ items: yesNoDropdown, searchEnabled: false, displayExpr:'name', valueExpr:'id', showClearButton: true }" [label]="{ text: 'Alternative manufacturing methods?' }"></dxi-item>
						</dxi-item>
						<dxi-item itemType="group" caption="Where statistical process control is used on similar products:">
							<dxi-item dataField="feasibilityQuestionAreProcessesInControl" editorType="dxSelectBox" [editorOptions]="{ items: yesNoDropdown, searchEnabled: false, displayExpr:'name', valueExpr:'id', showClearButton: true }" [label]="{ text: 'Are the processes in control and stable?' }"></dxi-item>
							<dxi-item dataField="feasibilityQuestionDoesCapabilityMeetCustomerRequirements" editorType="dxSelectBox" [editorOptions]="{ items: yesNoDropdown, searchEnabled: false, displayExpr:'name', valueExpr:'id', showClearButton: true }" [label]="{ text: 'Does process capability meet customer requirements?' }"></dxi-item>
						</dxi-item>
						<dxi-item dataField="feasibilityQuestionAdditionalDetails" editorType="dxTextArea" [label]="{ text: 'Additional Details' }" [editorOptions]="{ height: 100 }"></dxi-item>
					</dxi-item>
					<dxi-item *ngIf="item.feasibilityQuestionnaireCompletedOn != null" [colSpan]="2">Feasibility completed on {{formatDateTime(item.feasibilityQuestionnaireCompletedOn)}} by {{renderUser(item.feasibilityQuestionnaireCompletedById)}}</dxi-item>
				</dx-form>
			</div>
		</div>
	</dx-popup>
</div>
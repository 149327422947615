import { Environment } from "@environments/environment.interface";

export const environment: Environment = {
	build: {
		adminRoute: "admin",
		companyCommonName: "Birmingham Prototypes",
		companyLongName: "Birmingham Prototypes Ltd.",
		companyShortName: "BPL",
		flowId:82,
		lowestWorkOrderStagePriority: 25,
		production: true
	},
	server: {
		accessTokenExpiryMilliseconds: 3600000,
		accessTokenRefreshMilliseconds: 3540000,
		apiURL: "https://bpl-api.azurewebsites.net",
		storageSAS: "sv=2020-08-04&ss=bfqt&srt=sco&sp=rwdlacupitfx&se=2024-01-20T17:49:11Z&st=2022-01-20T09:49:11Z&sip=0.0.0.0-255.255.255.255&spr=https&sig=CLoqrfMwUaBxTL8VCvIHLQOW1zOPJr13hF%2Bs%2BkssD08%3D",
		storageUrl: "https://bplflow.blob.core.windows.net",
		stripeKey: ""
	}
};
<div class="operator-bg" style="overflow-x:auto; padding:20px">
	<div class="row">

	</div>
	<div class="row">
		<div class="col-1" style="padding-left:10px; padding-right:10px;" (click)="goBack()">
			<div style=" height:100%; background-color:#2a2434; color:white; border-radius:25px; text-align: center; margin-top:auto; padding-top:10px; border-color:#838088;" (tap)="goBack()">
				<i class="fal fa-arrow-left" style="font-size:30pt; padding-top:10px"></i>
			</div>
		</div>
		<div class="col-8">
			<div class="row"><h1 style="color:white;padding-left:15px;">GOODS <span *ngIf="direction == 0">IN</span><span *ngIf="direction == 1">OUT</span> - {{this.renderMode(this.mode).toUpperCase()}}</h1></div>
			<div class="row"><h2 style="color:#77787F; padding-left:15px;">{{this.renderSite(this.siteId)}}</h2></div>
			
			
		</div>
		<!-- <div class="col-2" style="padding-left:10px; padding-right:10px;">
			<div
				style="width:100%; height:50px; background-color:#1DFF00; border-radius:25px; text-align: center; margin-top:auto; padding-top:10px;">
				<h3>{{this.prodAct}}</h3>
			</div>
		</div>
		<div class="col-2" style="padding-left:10px; padding-right:10px;">
			<div
				style="width:100%; height:50px; background-color:#FFA500; border-radius:25px; text-align: center; margin-top:auto; padding-top:10px;">
				<h3>{{this.prodAmb}}</h3>
			</div>
		</div> -->
		<div class="col-3" style="padding-left:10px; padding-right:10px;">
			<div (click)="enterManual()"
				style="height:100%; height:50px; background-color:#2a2434; color:white; border-radius:25px; text-align: center; margin-top:auto; padding-top:10px; border-color:#838088;">
				<h3>ENTER MANUAL ID</h3>
			</div>
		</div>
	</div>
	<div class="row">
		<div *ngFor="let log of purchaseLogs" class="col-3 g-10">
			<div class="activeContainer" (click)="goPurchaseDetail(log.id)">
				<h3 style="color:white;">{{log.supplier.name}}
				</h3>
				<div class="row">
					<div class="col-12">
						<div class="row">
							<div class="col-4" style="padding:5px; color:#77787F;">PO Number <br /> <span
									style="color:white;">BPL-{{log.id}}</span></div>
							<div *ngIf="log.workOrderId == null" class="col-4" style="padding:5px; color:#77787F;">Work Order <br /> <span
									style="color:white;">N/A</span></div>
									<div *ngIf="log.workOrderId > 0" class="col-4" style="padding:5px; color:#77787F;">Work Order <br /> <span
										style="color:white;">WO{{log.workOrderId}}</span></div>
							<div class="col-4" style="padding:5px; color:#77787F;">Quantity <br /> <span
									style="color:white;">{{log.totalQuantity}}</span>
							</div>
						</div>
						<div class="row">
							<div class="col-4" style="padding:5px; color:#77787F;">Total Line Items <br /> <span
									style="color:white;">{{this.renderPlogLineItems(log.lineItems)}}</span></div>
									<div class="col-4" style="padding:5px; color:#77787F;">Due Date <br /> <span
										style="color:white;">{{renderPlogDueDate(log.dueDate)}}</span></div>
							<div class="col-4" style="padding:5px; color:#77787F;">Purchase Type <br /> <span
								style="color:white;">{{renderPlogType(log.purchaseType)}}</span>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
<div class="operator-bg" style="overflow-x:auto; padding:20px">
	<!-- More Info Modal -->
	<div id="mode" class="popup" *ngIf="showMoreInfo">
		<div class="row">
			<div class="col-8">
				<h2 style="margin-left:30px; margin-top:30px; color:white;">PO{{this.purchaseLogId}} - More Info</h2>
			</div>
			<div class="col-4">
				<div class="activeContainer" (click)="toggleMoreInfo()"
					style="bottom:0 !important; background-color: rgb(203, 72, 72); color:white; border-color:rgb(255, 0, 0); text-align: center;">
					Close
				</div>
			</div>
		</div>

		<p style="color:#77787F; padding-left:45px; padding-top:15px">Created at: {{renderDate(purchaseLog.createdAt)}}
		</p>
		<p style="color:#77787F; padding-left:45px;">Due Date: {{renderDate(purchaseLog.dueDate)}}</p>
		<p style="color:#77787F; padding-left:45px;">Contact Name: {{purchaseLog.contactName}}</p>
		<p style="color:#77787F; padding-left:45px;">Contact Email: {{purchaseLog.contactEmail}}</p>
		<p style="color:#77787F; padding-left:45px;">Raised by: {{purchaseLog.email}}</p>

	</div>
	<!-- End More Info Modal -->
	<!-- Blanket -->
	<div class="popup-blackout" *ngIf="showPopup"></div>
	<!-- End Blanket-->
	<div class="row">
		<div class="col-1" style="padding-left:10px; padding-right:10px;" (click)="goBack()">
			<div style=" height:100%; background-color:#2a2434; color:white; border-radius:25px; text-align: center; margin-top:auto; padding-top:10px; border-color:#838088;"
				(tap)="goBack()">
				<i class="fal fa-arrow-left" style="font-size:30pt; padding-top:10px"></i>
			</div>
		</div>
		<div class="col-8">
			<div class="row">
				<h1 style="color:white;padding-left:15px;">GOODS IN - PO{{this.purchaseLogId}}</h1>
			</div>
			<div class="row">
				<h2 style="color:#77787F; padding-left:15px;">{{this.purchaseLog.supplier.name}}</h2>
			</div>
		</div>
	</div>
	<div class="row">
		<div class="col-6 g-10" style="padding:20px;">
			<div class="activeContainer" style="height:250px">
				<h3 style="color:white;">Supplier Details</h3>
				<br />
				<p style="color:#77787F; padding-left:15px;">{{purchaseLog.supplier.name}}</p>
				<p style="color:#77787F; padding-left:15px;">{{purchaseLog.supplier.address1}}</p>
				<p style="color:#77787F; padding-left:15px;">{{purchaseLog.supplier.address2}}</p>
				<p style="color:#77787F; padding-left:15px;">{{purchaseLog.supplier.town}}</p>
				<p style="color:#77787F; padding-left:15px;">{{purchaseLog.supplier.postcode}}</p>
			</div>
		</div>
		<div class="col-6 g-10" style="padding:20px;">
			<div class="activeContainer" style="height:250px">
				<h3 style="color:white;">Actions</h3>
				<div class="row" *ngIf="false">
					<div class="col-6" style="padding:10px;">
						<div class="activeContainer" (click)="toggleMoreInfo()"
							style="background-color: cornflowerblue; color:white; border-color:darkcyan; text-align: center;">
							MORE INFO
						</div>
						<div class="activeContainer"
							style="background-color: rgb(237, 109, 100); color:white; border-color:rgb(139, 0, 0); text-align: center;">
							SUPERVISOR NEEDED
						</div>
					</div>
					<div class="col-6" style="padding:10px;">
						<div class="activeContainer"
							style="background-color: rgb(100, 237, 184); color:white; border-color:rgb(0, 139, 76); text-align: center;">
							PRINT BARCODE
						</div>
						<div class="activeContainer"
							style="background-color: rgb(145, 152, 0); color:rgb(255, 255, 255); border-color:rgb(132, 139, 0); text-align: center;">
							MORE INFO
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
	<div class="row">
		<div class="col-12 g-10" style="padding:20px;">
			<div class="activeContainer">
				<h3 style="color:white;">Line Items</h3>
				<div class="row">
					<div class="col-1">
						<h3 style="color:#77787F;">#</h3>
					</div>
					<div class="col-5">
						<h3 style="color:#77787F;">Description</h3>
					</div>
					<div class="col-2">
						<h3 style="color:#77787F;">Quantity</h3>
					</div>
					<div class="col-2">
						<h3 style="color:#77787F;">Recieved</h3>
					</div>
					<div class="col-2">
						<h3 style="color:#77787F;">Actions</h3>
					</div>
				</div>
				<div class="row" *ngFor="let lineItem of lineItems">
					<div class="col-1">
						<h3 style="color:white">{{lineItem.lineNumber}}</h3>
					</div>
					<div class="col-5">
						<h3 style="color:white">{{lineItem.lineDescription}}</h3>
					</div>
					<div class="col-2">
						<h3 style="color:white">{{lineItem.quantity}}</h3>
					</div>
					<div class="col-2">
						<h3 style="color:white">{{lineItem.receieved}}</h3>
					</div>
					<div class="col-2">
						<h3 style="color:white" *ngIf="!lineItemsComplete.includes(lineItem.lineNumber)"><i (click)="enterQuantityRecieved(lineItem)" class="fal fa-check"
								style="margin-left:10px;color:greenyellow"></i><i class="fal fa-times"
								style="margin-left:10px;color:red"></i></h3>
					</div>

				</div>
			</div>
		</div>
	</div>
	<div *ngIf="lineItemsComplete.length == lineItems.length" class="activeContainer" (click)="completePL()"
							style="background-color: rgb(145, 152, 0); color:rgb(255, 255, 255); border-color:rgb(132, 139, 0); text-align: center;">
							DONE
						</div>

</div>
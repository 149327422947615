import { Injectable, Injector } from "@angular/core";
import { Department } from "@enums/department";
import { environment } from "@environments/environment";
import { Service } from "@services/service";

@Injectable()
export class MachineService extends Service {
	constructor(injector: Injector) {
		super("Machine", injector);
	}

	// API
	appOpsPlanner(department: Department) {
		return this.http.get(`${environment.server.apiURL}/${this.endpoint}/App_OpsPlanner/${department}` );
	}

	appOpsPlannerForSite(siteId: number) {
		return this.http.get(`${environment.server.apiURL}/${this.endpoint}/App_OpsPlannerForSite/${siteId}` );
	}

	floorPlanMachineSummary(id: number) {
		return this.http.get(`${environment.server.apiURL}/${this.endpoint}/FloorPlanMachineSummary/${id}` );
	}

	getByDepartment(department: Department) {
		return this.http.get(`${environment.server.apiURL}/${this.endpoint}/GetByDepartment/${department}` );
	}

	getBySite(siteId: number) {
		return this.http.get(`${environment.server.apiURL}/${this.endpoint}/GetBySite/${siteId}` );
	}

	getCalendarPdf() {
		return this.http.get(`${environment.server.apiURL}/${this.endpoint}/GetCalendarPdf`, { responseType: "blob" as "json"} );
	}
	
	opsPlanner(department: Department) {
		return this.http.get(`${environment.server.apiURL}/${this.endpoint}/OpsPlanner/${department}` );
	}

	opsPlannerByDeviceId(deviceId: number) {
		return this.http.get(`${environment.server.apiURL}/${this.endpoint}/App_OpsPlannerByInterfaceDevice/${deviceId}` );
	}
	
	opsPlannerForSite(siteId: number) {
		return this.http.get(`${environment.server.apiURL}/${this.endpoint}/OpsPlannerForSite/${siteId}` );
	}

	requestStillageForStage(stageId: number){
		return this.http.post(`${environment.server.apiURL}/${this.endpoint}/App_RequestStillageForStage/${stageId}`, {} );
	}

	requestStillageSupervisor(stageId: number){
		return this.http.post(`${environment.server.apiURL}/${this.endpoint}/RequestStillageSupervisor/${stageId}`, {} );
	}

	requestSupervisorForStage(stageId: number){
		return this.http.post(`${environment.server.apiURL}/${this.endpoint}/App_RequestSupervisorForStage/${stageId}`, {} );
	}

	updateProductionStatus(id: number, status:number) {
		return this.http.post(`${environment.server.apiURL}/${this.endpoint}/App_UpdateProductionStatus/${id}/${status}`, {} );
	}

	updateProductionStatusSupervisor(id: number, status: number) {
		return this.http.post(`${environment.server.apiURL}/${this.endpoint}/UpdateProductionStatusSupervisor/${id}/${status}`, {} );
	}
}
export interface BulkGenerateWorkOrdersPdfLineItem {
	dateRequired: Date;
	includeInPdf: boolean;
	partNumber: string;
	quantity: number;
	workOrderId: number;
	
}

export class BulkGenerateWorkOrdersPdfLineItem implements BulkGenerateWorkOrdersPdfLineItem {
	constructor(data?: Partial<BulkGenerateWorkOrdersPdfLineItem>) {
		if (data) {
			for (const key of Object.keys(data)) {
				(this as any)[key] = (data as any)[key];
			}
		}
	}
}
import { Component } from "@angular/core";
import { AdminHeaderButton } from "@interfaces/adminHeaderButton.interface";
import { HelpDocumentService } from "@services/helpDocument.service";
import notify from "devextreme/ui/notify";

@Component({
	moduleId: module.id,
	selector: "help",
	styleUrls: ["help.css"],
	templateUrl: "help.html"
})
export class HelpAdminComponent {
	formDetails: any = { keywords: "" }
	headerPrimaryButtons: AdminHeaderButton[] = [];
	itemType = "Help Document"
	itemUrl: string = location.href.split("/")[3] + "/" + location.href.split("/")[4];
	items: any[] = []; 
    submitButtonOptions = { text: "Search", useSubmitBehavior: true }
	title: string;
	
	constructor(private itemService: HelpDocumentService) {
		this.title = `${this.itemType}s`;
		this.getItems();
	}

	getItems() {
		this.itemService.getAll()
			.subscribe(
				(res: any) => this.items = res.response.items,
				(err) => {
					console.log(err);
					notify("Something went wrong!", "error", 5000);
				},
			);
	}
 
	handleSubmit (e: any) {
		if (this.formDetails.keywords.trim() === "") {
			this.getItems();
		} else {
			this.search();
		}
		e.preventDefault();
	}

	headerButtonClick(method: any) {
		// @ts-ignore // Required to be able to call the method directly from the variable
		if (this[method]) this[method]();
	}	

	search() {
		this.itemService.search(this.formDetails.keywords.trim())
			.subscribe(
				(res: any) => this.items = res.response,
				(err) => {
					console.log(err);
					notify("Something went wrong!", "error", 5000);
				},
			);
		return false;
	}
}
import { Component, ViewEncapsulation } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { PurchaseType } from "@enums/purchaseType.enum";
import { GetPropertiesFromEnum } from "@helpers/getPropertiesFromEnum";
import { PurchaseLogGridLineItem } from "@interfaces/purchaseLogGridLineItem.interface";
import { PurchaseLogGridLineItem_App } from "@interfaces/purchaseLogGridLineItem_App.interface";
import { PurchaseLogService } from "@services/purchaseLog.service";
import { StockStoreService } from "@services/stockStore.service";
import notify from "devextreme/ui/notify";

@Component({
	encapsulation: ViewEncapsulation.None,
	moduleId: module.id,
	selector: "purchaseDetail",
	styleUrls: ["purchaseDetail.css"],
	templateUrl: "purchaseDetail.html"
})
export class PurchaseDetailComponent {
	incorrectItems: PurchaseLogGridLineItem_App[] = [];
	isLoading = false;
	lineItems: PurchaseLogGridLineItem_App[] = [];
	lineItemsComplete: number[] = [];
	notRecieved = false;
	purchaseLog: any;
	purchaseLogId = 0;
	purchaseTypes: any[] = [];
	showMoreInfo = false;
	showPopup = false;
	site = "";

	constructor(private route: ActivatedRoute, private router: Router, private stockStoreService: StockStoreService, private plogService: PurchaseLogService) {
		this.purchaseTypes = GetPropertiesFromEnum(PurchaseType);
		this.route.params
			.subscribe(async (params) => {
				this.purchaseLogId = Number.parseInt(params.id);
				this.getPlog(Number.parseInt(params.id));
			});
	}

	commitToStores(logLine: PurchaseLogGridLineItem_App) {
		this.stockStoreService.App_Recieved(this.purchaseLogId, logLine)
			.subscribe(
				(res: any) => {
					this.purchaseLog = res.response;
					console.log(res.response.lineItems);
					this.lineItems = JSON.parse(res.response.lineItems);
					console.log(this.lineItems);
				},
				(err) => {
					console.log(err);
					notify("Something Went Wrong!", "Error", 5000);
				}
			);
	}

	completePL() {
		if (this.notRecieved) {
			this.plogService.app_CompleteMissing(this.purchaseLogId)
				.subscribe(
					() => {
						notify("Goods Logged", "Success", 5000);
						this.printDialog();
					},
					(err) => {
						console.log(err);
						notify("Something Went Wrong!", "Error", 5000);
					}
				);
		} else {
			this.plogService.app_Complete(this.purchaseLogId)
				.subscribe(
					() => {
						notify("Goods Logged", "Success", 5000);
						this.printDialog();
					},
					(err) => {
						console.log(err);
						notify("Something Went Wrong!", "Error", 5000);
					}
				);
		}
	}

	enterNotRecieved(log: PurchaseLogGridLineItem_App) {
		this.lineItems.find(x => x.lineNumber == log.lineNumber)!.receieved = 0;
		this.lineItemsComplete.push(log.lineNumber);
		this.notRecieved = true;
	}

	enterQuantityRecieved(log: PurchaseLogGridLineItem_App) {
		// Goods checked?
		if (window.confirm("If goods have been checked click confirm") == false) { return; }
		// if yes, goods acceptable?
		if (window.confirm("If goods have are acceptable click confirm") == false) { return; }
		const origQty = log.quantity;
		const input = window.prompt("Enter Quantity Recieved", log.quantity.toString());
		if (input == null) {
			return;
		}
		const inputCon = Number.parseInt(input!);
		this.lineItems.find(x => x.lineNumber == log.lineNumber)!.receieved = inputCon;

		//if full quantity not received pop it in the incorrect pile.
		if (inputCon < log.quantity) {
			this.incorrectItems.push(this.lineItems.find(x => x.lineNumber == log.lineNumber)!);
		}

		const totalPallets = window.prompt("Enter Total Pallets for Item.", "1");

		const totalPalletsCon = Number.parseInt(totalPallets!);
		console.log(totalPalletsCon);
		if (totalPallets != null) {
			if (totalPalletsCon > 1) {
				let i = 1;
				while (i <= totalPalletsCon) {
					console.log("Pallet Number" + i);
					const palletQtyInput = window.prompt("Enter Quantity for Pallet " + i, "0");
					const palletQty = Number.parseInt(palletQtyInput!);
					const tempLog = log;
					tempLog.quantity = palletQty;
					this.commitToStores(tempLog);
					i++;
				}
			} else {
				// Just the one.
				this.commitToStores(log);
				const tempLog = log;
				tempLog.quantity = inputCon;
			}
			this.lineItemsComplete.push(log.lineNumber);
			log.quantity = origQty;
		}
	}

	getPlog(id: number) {
		this.plogService.app_GetById(id)
			.subscribe(
				(res: any) => {
					this.purchaseLog = res.response;
					console.log(res.response.lineItems);
					this.lineItems = JSON.parse(res.response.lineItems);
					console.log(this.lineItems);
				},
				(err) => {
					console.log(err);
					notify("Something Went Wrong!", "Error", 5000);
				}
			);
	}

	goBack() {
		window.history.back();
	}

	printDialog(){
		if (window.confirm("Please enter " + this.purchaseLogId + " at the kiosk") == false) { return; }
		this.goBack();
	}

	renderDate(date: string) {
		return new Date(date).toLocaleDateString("en-GB");
	}

	renderPlogLineItems(lineItems: string) {
		const lineItemsConverted: PurchaseLogGridLineItem[] = JSON.parse(lineItems);
		return (lineItemsConverted.length);
	}

	renderPlogType(id: number) {
		return this.purchaseTypes.filter(x => x.id == id)[0].name;
	}

	renderSite(id: number) {
		switch (id) {
			case 1:
				return "KINGS NORTON";
			case 2:
				return "SELLY OAK";
			case 3:
				return "REDDITCH";
			default:
				return "KINGS NORTON";
		}
	}

	toggleMoreInfo() {
		this.showPopup = !this.showPopup;
		this.showMoreInfo = !this.showMoreInfo;
	}
}
<div class="operator-bg" style="overflow-x:auto; padding:20px">
	<!-- Outside Recieved -->
	<div id="complete" class="popup" style="height:474px; margin-top:0px;" *ngIf="showOutsideRecieved">
		<div style="height:40px;"></div>
		<span style="font-size:35px; text-align: left; color:white; margin-left:42px; margin-top:40px !important;">
			How many parts recieved?</span>
		<div class="row">
			<input class="input-override" type="number" name="quantity" (keyup.enter)="outsideRecieved()"
				[(ngModel)]="quantityEntered" placeholder="Quantity" autocomplete="off"
				style="margin-left:1vw; width: 48vw;margin-top:50px;border-radius:10px; height:100px; font-size: 40px; padding-left:10px;">
		</div>
		<div class="row">
			<div (click)="outsideRecieved()"
				style="padding-top:20px; margin-left:1vw; margin-top:40px; width:23vw; height:125px; border:5px solid #A7FFA7; background-color: #6ce468;  text-align: center; border-radius:25px">
				<span style="font-size: 60px; color:white">SUBMIT</span>
			</div>
			<div (click)="showOutsideRecieved = false; showPopup = false"
				style="padding-top:20px; margin-left:2vw; margin-top:40px; width:23vw; height:125px; border:5px solid #a7ddff; background-color: #559be2;  text-align: center;  border-radius:25px">
				<span style="font-size: 60px; color:white">CANCEL</span>
			</div>
		</div>
	</div>
	<!-- Outside Sent -->
	<div id="complete" class="popup" style="height:auto; margin-top:0px; padding-bottom:40px" *ngIf="showOutsideSent">
		<div style="height:40px;"></div>
		<span style="font-size:35px; text-align: left; color:white; margin-left:42px; margin-top:10vh !important;">
			Are you sure you wish to mark these parts as dispatched?</span>
		<div class="row">
			<div (click)="outsideSent()"
				style="padding-top:20px; margin-left:1vw; margin-top:40px; width:23vw; height:125px; border:5px solid #A7FFA7; background-color: #6ce468;  text-align: center; border-radius:25px">
				<span style="font-size: 60px; color:white">CONFIRM</span>
			</div>
			<div (click)="showOutsideSent = false; showPopup = false"
				style="padding-top:20px; margin-left:2vw; margin-top:40px; width:23vw; height:125px; border:5px solid #a7ddff; background-color: #559be2;  text-align: center;  border-radius:25px">
				<span style="font-size: 60px; color:white">CANCEL</span>
			</div>
		</div>
	</div>
	<!-- Customer Sent -->
	<div id="complete" class="popup" style="height:auto; margin-top:0px; padding-bottom:40px" *ngIf="showCustomerSent">
		<div style="height:40px;"></div>
		<span style="font-size:35px; text-align: left; color:white; margin-left:42px; margin-top:10vh !important;">
			Are you sure you wish to mark these parts as dispatched?</span>
		<div class="row">
			<div (click)="customerSent()"
				style="padding-top:20px; margin-left:1vw; margin-top:40px; width:23vw; height:125px; border:5px solid #A7FFA7; background-color: #6ce468;  text-align: center; border-radius:25px">
				<span style="font-size: 60px; color:white">CONFIRM</span>
			</div>
			<div (click)="showCustomerSent = false; showPopup = false"
				style="padding-top:20px; margin-left:2vw; margin-top:40px; width:23vw; height:125px; border:5px solid #a7ddff; background-color: #559be2;  text-align: center;  border-radius:25px">
				<span style="font-size: 60px; color:white">CANCEL</span>
			</div>
		</div>
	</div>
	<!-- Blackout Start -->
	<div class="popup-blackout" *ngIf="showPopup"></div>
	<!-- Blackout End -->
	<div class="row">
		<div class="col-1" style="padding-left:10px; padding-right:10px;" (click)="goBack()">
			<div style=" height:100%; background-color:#2a2434; color:white; border-radius:25px; text-align: center; margin-top:auto; padding-top:10px; border-color:#838088;"
				(tap)="goBack()">
				<i class="fal fa-arrow-left" style="font-size:30pt; padding-top:10px"></i>
			</div>
		</div>
		<div class="col-8">
			<div class="row">
				<h1 style="color:white;padding-left:15px;">GOODS <span *ngIf="direction == 0">IN</span><span
						*ngIf="direction == 1">OUT</span> - {{this.renderMode(this.mode).toUpperCase()}}</h1>
			</div>
			<div class="row">
				<h2 style="color:#77787F; padding-left:15px;">{{this.renderSite(this.siteId)}}</h2>
			</div>


		</div>
		<!-- <div class="col-2" style="padding-left:10px; padding-right:10px;">
			<div
				style="width:100%; height:50px; background-color:#1DFF00; border-radius:25px; text-align: center; margin-top:auto; padding-top:10px;">
				<h3>{{this.prodAct}}</h3>
			</div>
		</div>
		<div class="col-2" style="padding-left:10px; padding-right:10px;">
			<div
				style="width:100%; height:50px; background-color:#FFA500; border-radius:25px; text-align: center; margin-top:auto; padding-top:10px;">
				<h3>{{this.prodAmb}}</h3>
			</div>
		</div> -->
		<!-- <div class="col-3" style="padding-left:10px; padding-right:10px;">
			<div (click)="enterManual()"
				style="height:100%; height:50px; background-color:#2a2434; color:white; border-radius:25px; text-align: center; margin-top:auto; padding-top:10px; border-color:#838088;">
				<h3>ENTER MANUAL ID</h3>
			</div>
		</div> -->
	</div>
	<!-- Purchases -->
	<div *ngIf="purchaseLogSuppliers && supplierSelected == false" class="row">
		<div class="col-12 g-10" style="padding:0.5vw;" (click)="goMoveStock()">
			<div class="activeContainer" style="text-align: center; height:10vh; line-height:5vh; padding-top:2vh; border-color:white; color:white !important;">
				<h1 style="color:white;line-height:5vh;">PLACE STOCK
				</h1>
			</div>
		</div>
	</div>
	<div *ngIf="purchaseLogSuppliers && supplierSelected == false" class="row">
		<div *ngFor="let log of purchaseLogSuppliers" class="col-3 g-10">
			<div class="activeContainer" (click)="selectSupplier(log.id)">
				<h3 style="color:white;">{{log.name}}
				</h3>
			</div>
		</div>
	</div>
	<div *ngIf="purchaseLogs && supplierSelected == true" class="row">
		<div *ngFor="let log of purchaseLogs" class="col-3 g-10">
			<div class="activeContainer" (click)="goPurchaseDetail(log.id)">
				<h3 style="color:white;">BPL-{{log.id}}
				</h3>
				<div class="row">
					<div class="col-12">
						<div class="row">
							<div class="col-4" style="padding:5px; color:#77787F;">Supplier <br /> <span
									style="color:white;">{{log.supplier.name}}</span></div>
							<div *ngIf="log.workOrderId == null" class="col-4" style="padding:5px; color:#77787F;">Work
								Order <br /> <span style="color:white;">N/A</span></div>
							<div *ngIf="log.workOrderId > 0" class="col-4" style="padding:5px; color:#77787F;">Work
								Order <br /> <span style="color:white;">WO{{log.workOrderId}}</span></div>
							<div class="col-4" style="padding:5px; color:#77787F;">Quantity <br /> <span
									style="color:white;">{{log.totalQuantity}}</span>
							</div>
						</div>
						<div class="row">
							<div class="col-4" style="padding:5px; color:#77787F;">Total Line Items <br /> <span
									style="color:white;">{{this.renderPlogLineItems(log.lineItems)}}</span></div>
							<div class="col-4" style="padding:5px; color:#77787F;">Due Date <br /> <span
									style="color:white;">{{renderPlogDueDate(log.dueDate)}}</span></div>
							<div class="col-4" style="padding:5px; color:#77787F;">Purchase Type <br /> <span
									style="color:white;">{{renderPlogType(log.purchaseType)}}</span>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
	<!-- Outside In -->
	<div *ngIf="machines.length > 0 && this.direction == 0" class="row">
		<div *ngFor="let machine of machines" class="col-4 g-10">
			<div *ngFor="let log of machine.runningStages" class="col-12 g-10">
				<div *ngIf="log.quantityCompleted > 0" class="activeContainer" (click)="selectStage(log)">
					<h3 style="color:white;">{{log.partNumber}}
					</h3>
					<div class="row">
						<div class="col-9">
							<div class="row">
								<div class="col-4" style="padding:5px; color:#77787F;">Work
									Order <br /> <span style="color:white;">WO{{log.workOrderId}}</span></div>
								<div class="col-4" style="padding:5px; color:#77787F;">Quantity <br /> <span
										style="color:white;">{{log.quantityRequired}}</span>
								</div>

							</div>
							<div class="row">
								<div class="col-4" style="padding:5px; color:#77787F;">Operation <br /> <span
										style="color:white;">{{log.operationNumber}}</span></div>
								<div class="col-4" style="padding:5px; color:#77787F;">Due Date <br /> <span
										style="color:white;">{{renderPlogDueDate(log.dateRequired)}}</span></div>
							</div>
						</div>
						<div class="col-3">
							<img style="height:115px; max-width:100%; max-height:215px !important;"
								[src]="log.partImage">
						</div>
					</div>
					<div class="row">
						<h4 style="color:rgb(193, 193, 193);">{{machine.machineName}}
						</h4>
					</div>
				</div>
			</div>
		</div>
	</div>
	<!-- Outside Out -->
	<div *ngIf="machines.length > 0 && this.direction == 1" class="row">
		<div *ngFor="let machine of machines" class="col-4 g-10">
			<div *ngFor="let log of machine.runningStages" class="col-12 g-10">
				<div *ngIf="log.quantityCompleted < 1" class="activeContainer" (click)="selectStage(log)">
					<h3 style="color:white;">{{log.partNumber}}
					</h3>
					<div class="row">
						<div class="col-9">
							<div class="row">
								<div class="col-4" style="padding:5px; color:#77787F;">Work
									Order <br /> <span style="color:white;">WO{{log.workOrderId}}</span></div>
								<div class="col-4" style="padding:5px; color:#77787F;">Quantity <br /> <span
										style="color:white;">{{log.quantityRequired}}</span>
								</div>

							</div>
							<div class="row">
								<div class="col-4" style="padding:5px; color:#77787F;">Operation <br /> <span
										style="color:white;">{{log.operationNumber}}</span></div>
								<div class="col-4" style="padding:5px; color:#77787F;">Due Date <br /> <span
										style="color:white;">{{renderPlogDueDate(log.dateRequired)}}</span></div>
							</div>
						</div>
						<div class="col-3">
							<img style="height:115px; max-width:100%; max-height:215px !important;"
								[src]="log.partImage">
						</div>
					</div>
					<div class="row">
						<h4 style="color:rgb(193, 193, 193);">{{machine.machineName}}
						</h4>
					</div>
				</div>
			</div>
			<div *ngFor="let log of machine.availableStages" class="col-12 g-10">
				<div *ngIf="log.quantityCompleted < 1" class="activeContainer" (click)="selectStage(log)">
					<h3 style="color:white;">{{log.partNumber}}
					</h3>
					<div class="row">
						<div class="col-9">
							<div class="row">
								<div class="col-4" style="padding:5px; color:#77787F;">Work
									Order <br /> <span style="color:white;">WO{{log.workOrderId}}</span></div>
								<div class="col-4" style="padding:5px; color:#77787F;">Quantity <br /> <span
										style="color:white;">{{log.quantityRequired}}</span>
								</div>

							</div>
							<div class="row">
								<div class="col-4" style="padding:5px; color:#77787F;">Operation <br /> <span
										style="color:white;">{{log.operationNumber}}</span></div>
								<div class="col-4" style="padding:5px; color:#77787F;">Due Date <br /> <span
										style="color:white;">{{renderPlogDueDate(log.dateRequired)}}</span></div>
							</div>
						</div>
						<div class="col-3">
							<img style="height:115px; max-width:100%; max-height:215px !important;"
								[src]="log.partImage">
						</div>
					</div>
					<div class="row">
						<h4 style="color:rgb(193, 193, 193);">{{machine.machineName}}
						</h4>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
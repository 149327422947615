import { Injectable, Injector } from "@angular/core";
import { environment } from "@environments/environment";
import { CloseSalesEnquiry } from "@interfaces/closeSalesEnquiry.interface";
import { SalesEnquiry } from "@interfaces/salesEnquiry.interface";
import { Service } from "@services/service";

@Injectable()
export class SalesEnquiryService extends Service {
	constructor(injector: Injector) {
		super("SalesEnquiry", injector);
	}

	// API
	approveQuote(id: number){
		return this.http.post(`${environment.server.apiURL}/${this.endpoint}/Approve/${id}`, {});
	}

	closeSalesEnquiry(id: number, values: CloseSalesEnquiry){
		return this.http.post(`${environment.server.apiURL}/${this.endpoint}/CloseSalesEnquiry/${id}`, values);
	}

	completeFeasibility(data: SalesEnquiry){
		return this.http.post(`${environment.server.apiURL}/${this.endpoint}/CompleteFeasibility`, data);
	}

	duplicateSalesEnquiry(id: number){
		return this.http.post(`${environment.server.apiURL}/${this.endpoint}/Duplicate/${id}`, {});
	}

	feasibilitySignOffProduction(id: number){
		return this.http.post(`${environment.server.apiURL}/${this.endpoint}/FeasibilitySignOffProduction/${id}`, {});
	}

	feasibilitySignOffQuality(id: number){
		return this.http.post(`${environment.server.apiURL}/${this.endpoint}/FeasibilitySignOffQuality/${id}`, {});
	}

	generateAndEmailPdf(id: number) {
		return this.http.get(`${environment.server.apiURL}/${this.endpoint}/GenerateAndEmailPdf/${id}`);
	}

	generateAndPreviewPdf(id: number) {
		return this.http.get(`${environment.server.apiURL}/${this.endpoint}/GenerateAndPreviewPdf/${id}`, { responseType: "blob" as "json"} );
	}

	getByProjectId(id: number) {
		return this.http.get(`${environment.server.apiURL}/${this.endpoint}/GetByProjectId/${id}`);
	}

	getEnquiries(){
		return this.http.get(`${environment.server.apiURL}/${this.endpoint}/GetEnquiries/`);
	}

	getProjectPotentialQuantity(id: number)
	{
		return this.http.get(`${environment.server.apiURL}/${this.endpoint}/GetProjectPotentialQuantity/${id}`);
	}
	
	getProjectPotentialValue(id: number)
	{
		return this.http.get(`${environment.server.apiURL}/${this.endpoint}/GetProjectPotentialValue/${id}`);
	}

	getRequiringFeasibilitySignOff(){
		return this.http.get(`${environment.server.apiURL}/${this.endpoint}/SalesEnquiriesRequiringFeasibilitySignOff/`);
	}

	myBreakdown() {
		return this.http.get(`${environment.server.apiURL}/${this.endpoint}/MyBreakdown`);
	}

	mySummary() {
		return this.http.get(`${environment.server.apiURL}/${this.endpoint}/MySummary`);
	}

	quotesSent() {
		return this.http.get(`${environment.server.apiURL}/${this.endpoint}/QuotesSent`);
	}

	quotesWaitingApproval() {
		return this.http.get(`${environment.server.apiURL}/${this.endpoint}/QuotesWaitingApproval`);
	}

	requestApproval(id: number){
		return this.http.post(`${environment.server.apiURL}/${this.endpoint}/RequestApprovalFor/${id}`, {});
	}

	thirtyDayValue() {
		return this.http.get(`${environment.server.apiURL}/${this.endpoint}/ThirtyDayValue`);
	}	
}
export interface BulkGenerateWorkOrdersPdf {
	workOrders: string; // JSON List of BulkGenerateWorkOrdersPdfLineItem lines
}

export class BulkGenerateWorkOrdersPdf implements BulkGenerateWorkOrdersPdf {
	constructor(data?: Partial<BulkGenerateWorkOrdersPdf>) {
		if (data) {
			for (const key of Object.keys(data)) {
				(this as any)[key] = (data as any)[key];
			}
		}
	}
}
import { Component, ViewChild, ViewEncapsulation } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { AdminHeaderButton } from "@interfaces/adminHeaderButton.interface";
import { Client } from "@interfaces/client.interface";
import { ClientLocation } from "@interfaces/clientLocation.interface";
import { Invoice } from "@interfaces/invoice.interface";
import { InvoiceGridLineItem } from "@interfaces/invoiceGridLineItem.interface";
import { InvoiceGridRow } from "@interfaces/invoiceGridRow.interface";
import { ClientService } from "@services/client.service";
import { ClientLocationService } from "@services/clientLocation.service";
import { DeliveryNoteService } from "@services/deliveryNote.service";
import { InvoiceService } from "@services/invoice.service";
import { SalesOrderService } from "@services/salesOrder.service";
import { DxDataGridComponent } from "devextreme-angular";
import notify from "devextreme/ui/notify";
import { Guid } from "guid-typescript";

@Component({
	encapsulation: ViewEncapsulation.None,
	moduleId: module.id,
	selector: "invoices-id",
	styleUrls: ["invoices-id.css"],
	templateUrl: "invoices-id.html"
})
export class InvoicesIdAdminComponent {
	@ViewChild("gridContainer", { static: false }) dataGrid!: DxDataGridComponent;
	changes: any = [];
	clientLocations: ClientLocation[] = [];
	clients: Client[] = [];
	createEnabled = true;
	editEnabled = true;
	editRowKey: any = null;
	headerPrimaryButtons: AdminHeaderButton[] = [];
	headerSecondaryButtons: AdminHeaderButton[] = [];
	headerTertiaryButtons: AdminHeaderButton[] = [];
	invoiceGridRow: InvoiceGridRow = new InvoiceGridRow();
	item: Invoice = new Invoice();
	itemType = "Invoice";
	itemUrl: string = location.href.split("/")[3] + "/" + location.href.split("/")[4];
	lineItems: InvoiceGridLineItem[] = [];	
	popupAddButtonOptions: any;
	popupCloseButtonOptions: any = { onClick: () => this.closePopup(), text: "Close" };
	popupTitle = "";
	popupVisible = false;
	readOnly = true;
	readOnlyAlways = true;
	returnUrl: string = location.href.split("/")[3] + "/" + location.href.split("/")[4];
	screenMode = "";
	title: string = "View " + this.itemType;

	constructor(private router: Router, private route: ActivatedRoute, private itemService: InvoiceService, private clientService: ClientService, private clientLocationService: ClientLocationService, private deliveryNoteService: DeliveryNoteService, private salesOrderService: SalesOrderService) {
		this.getClients();
		this.route.params.subscribe((params) => {
			this.getItem(params.id, "view");
		});
	}

	buildButtons(mode: string) {
		this.screenMode = mode;
		this.headerSecondaryButtons = [];
		this.headerTertiaryButtons = [];

		if (mode == "view") {
			this.headerPrimaryButtons = [
				{ method: "updateNotesPopup", text: "Edit Notes" },
				{ method: "close", text: "Close" }
			];
			this.headerTertiaryButtons = [
				{ method: "generatePreviewPDF", text: "Download PDF" },
				{ method: "markSent", text: "Mark as Sent" }
			];

			if (this.item.completed === false) {
				this.headerTertiaryButtons.push({ method: "markCompleted", text: "Mark as Completed" });
			}
		}

		if (this.item.salesOrderId !== undefined && this.item.salesOrderId !== null && this.item.salesOrderId > 0) {
			this.headerTertiaryButtons.push({ method: "viewSalesOrder", text: "Go to Sales Order" });
		}

		if (this.item.deliveryNoteId !== undefined && this.item.deliveryNoteId !== null && this.item.deliveryNoteId > 0) {
			this.headerTertiaryButtons.push({ method: "viewDeliveryNote", text: "Go to Delivery Note" });
		}
	}

	close() {
		this.router.navigate([`/${this.returnUrl}/`]);
	}

	closePopup() {
		this.popupVisible = false;
	}

	formDataChanged(args: any) {
		switch (args.dataField) {
			case "clientId":
				this.getClientLocations();
				break;
		}
	}

	generatePreviewPDF() {
		this.itemService.generateAndPreviewPdf(this.item.id).subscribe(
			(response: any) =>{
				const dataType = response.type;
				const binaryData = [];
				binaryData.push(response);
				const downloadLink = document.createElement("a");
				downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, {type: dataType}));
				downloadLink.setAttribute("download", "INV" + this.item.id + "-" + this.clients.find(x => x.id == this.item.clientId)?.name +  ".pdf");
				document.body.appendChild(downloadLink);
				downloadLink.click();
			}
		);
	}
	
	getClientLocations() {
		this.clientLocationService.getLocationsByClientId(this.item.clientId)
			.subscribe(
				(res: any) =>  {
					this.clientLocations = res.response;
					this.clientLocations.sort((a,b) => a.name.localeCompare(b.name));
				},
				(err) => console.log(err)
			);
	}
	
	getClients() {
		this.clientService.getAll()
			.subscribe(
				(res: any) =>  {
					this.clients = res.response.items;
					this.clients.sort((a,b) => a.name.localeCompare(b.name));
				},
				(err) => console.log(err)
			);
	}
	
	getInvoiceGridRow() {
		this.itemService.getGridForInvoice(this.item.id)
			.subscribe(
				(res: any) =>  {
					this.invoiceGridRow = res.response;
					this.lineItems = JSON.parse(this.invoiceGridRow.lineItems);
				},
				(err) => console.log(err)
			);
	}

	getItem(lookupId: Guid, buttonMode: string) {
		this.itemService.getSingleByLookupId(lookupId)
			.subscribe(
				(res: any) => {
					this.item = res.response;
					this.getClientLocations();
					this.getInvoiceGridRow();
					this.buildButtons(buttonMode);
				},
				(err) => {
					console.log(err);
					notify("Something Went Wrong!", "Error", 5000);
				},
			);
	}

	headerButtonClick(method: any) {
		// @ts-ignore // Required to be able to call the method directly from the variable
		if (this[method]) this[method]();
	}

	markCompleted() {
		if (confirm("Are you sure you wish to mark this invoice as completed? This cannot be undone.") === false) {
			return;
		}
		
		this.itemService.markCompleted(this.item.id)
			.subscribe(
				() => {
					notify("Successfully Completed", "success", 5000);
					this.getItem(this.item.lookupId, "view");
				},
				(err) => {
					console.log(err);
					notify("Something went wrong!", "error", 5000);
				}
			);
	}

	markSent() {
		this.itemService.markSent(this.item.id)
			.subscribe(
				() => {
					notify("Successfully Updated", "success", 5000);
					this.getItem(this.item.lookupId, "view");
				},
				(err) => {
					console.log(err);
					notify("Something went wrong!", "error", 5000);
				}
			);
	}

	updateItem() {
		this.item.lineItems = JSON.stringify(this.lineItems);
		this.itemService.updateSingleById(this.item.id, this.item)
			.subscribe(
				() => {
					notify("Successfully Updated", "success", 5000);
					// this.cancelEditing();
				},
				(err) => {
					console.log(err);
					notify("Something went wrong!", "error", 5000);
				}
			);
	}

	updateLineItems() {
		this.item.lineItems = JSON.stringify(this.lineItems);
		this.itemService.updateLineItems(this.item.id, this.item)
			.subscribe(
				() => {
					notify("Successfully Updated", "success", 5000);
					this.closePopup();
				},
				(err) => {
					console.log(err);
					notify("Something went wrong!", "error", 5000);
				}
			);
	}

	updateNotes() {
		this.itemService.updateNotes(this.item.id, this.item)
			.subscribe(
				() => {
					notify("Successfully Updated", "success", 5000);
					this.closePopup();
				},
				(err) => {
					console.log(err);
					notify("Something went wrong!", "error", 5000);
				}
			);
	}

	updateNotesPopup() {
		this.popupTitle = "Edit Notes";
		this.popupVisible = true;
		this.popupAddButtonOptions = { onClick: () => this.updateNotes(), text: "Save Changes" };
		return false;
	}
	
	viewDeliveryNote() {
		if (this.item.deliveryNoteId === undefined || this.item.deliveryNoteId === null || this.item.deliveryNoteId < 1) {
			return;
		}
		this.deliveryNoteService.getSingleById(this.item.deliveryNoteId)
			.subscribe(
				(res: any) =>  {
					const deliveryNote = res.response;
					this.router.navigate([`admin/deliveryNotes/${deliveryNote?.lookupId}`]);
				},
				(err) => console.log(err)
			);
	}
	
	viewSalesOrder() {
		this.salesOrderService.getSingleById(this.item.salesOrderId)
			.subscribe(
				(res: any) =>  {
					const salesOrder = res.response;
					this.router.navigate([`admin/salesOrders/${salesOrder?.lookupId}`]);
				},
				(err) => console.log(err)
			);
	}
}
<admin-header [primaryButtons]="headerPrimaryButtons" [tertiaryButtons]="headerTertiaryButtons" [title]="title" (eventFired)="headerButtonClick($event)"></admin-header>
<div class="form-container">
	<dx-form class="m-3" [(formData)]="item" [showColonAfterLabel]="true" labelLocation="top" [readOnly]="readOnly">
		<dxi-item itemType="group" caption="Details">
			<dxi-item dataField="promiseDate" editorType="dxDateBox" [editorOptions]="{ displayFormat: 'dd/MM/yyyy', type: 'date' }" [label]="{ text: 'Promise Date' }"><dxi-validation-rule type="required"></dxi-validation-rule></dxi-item>
			<dxi-item dataField="promiseDepartment" editorType="dxSelectBox" [editorOptions]="{ items: promiseDepartments, displayExpr:'name', valueExpr:'id', searchEnabled: true }" [colSpan]="3"><dxi-validation-rule type="required"></dxi-validation-rule></dxi-item>
			<dxi-item dataField="promiseType" editorType="dxSelectBox" [editorOptions]="{ items: promiseTypes, displayExpr:'name', valueExpr:'id', searchEnabled: true }" [colSpan]="3"><dxi-validation-rule type="required"></dxi-validation-rule></dxi-item>
			<dxi-item dataField="description"><dxi-validation-rule type="required"></dxi-validation-rule></dxi-item>
			<dxi-item dataField="completed" editorType="dxCheckBox"></dxi-item>
			<dxi-item dataField="isPrivate" editorType="dxCheckBox"></dxi-item>
		</dxi-item>
	</dx-form>
</div>
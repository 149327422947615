import { Injectable, Injector } from "@angular/core";
import { environment } from "@environments/environment";
import { ChangeWorkOrderStageMachine } from "@interfaces/changeWorkOrderStageMachine.interface";
import { ChangeWorkOrderStageOperator } from "@interfaces/changeWorkOrderStageOperator.interface";
import { ChangeWorkOrderStagePriority } from "@interfaces/changeWorkOrderStagePriority.interface";
import { Service } from "@services/service";

@Injectable()
export class WorkOrderStageService extends Service {
	constructor(injector: Injector) {
		super("WorkOrderStage", injector);
	}

	App_togglePause(id: number){
		return this.http.post(`${environment.server.apiURL}/${this.endpoint}/App_TogglePause/${id}`, {});
	}

	changeMachine(data: ChangeWorkOrderStageMachine){
		return this.http.post(`${environment.server.apiURL}/${this.endpoint}/ChangeMachine`, data);
	}

	changeOperator(data: ChangeWorkOrderStageOperator){
		return this.http.post(`${environment.server.apiURL}/${this.endpoint}/ChangeOperator`, data);
	}

	changePriority(data: ChangeWorkOrderStagePriority){
		return this.http.post(`${environment.server.apiURL}/${this.endpoint}/ChangePriority`, data);
	}

	completedQuantity(id: number, quantity: number){
		return this.http.post(`${environment.server.apiURL}/${this.endpoint}/CompletedQuantity/${id}/${quantity}`, {});
	}

	completedQuantityApiKey(id: number, quantity: number){
		return this.http.post(`${environment.server.apiURL}/${this.endpoint}/App_IncreaseCompletedQuantity/${id}/${quantity}`, {});
	}

	getForWorkOrder(id: number){
		return this.http.get(`${environment.server.apiURL}/${this.endpoint}/ForWorkOrder/${id}`);
	}

	increaseScrapAmount(id: number, quantity: number){
		return this.http.post(`${environment.server.apiURL}/${this.endpoint}/App_IncreaseScrappedQuantity/${id}/${quantity}`, {});
	}

	reduceQuantity(id: number, quantity: number){
		return this.http.post(`${environment.server.apiURL}/${this.endpoint}/ReduceQuantity/${id}/${quantity}`, {});
	}

	requiredAmountAdjustment(id: number, quantity: number){
		return this.http.post(`${environment.server.apiURL}/${this.endpoint}/App_AdjustQuantity/${id}/${quantity}`, {});
	}

	togglePause(id: number){
		return this.http.post(`${environment.server.apiURL}/${this.endpoint}/TogglePause/${id}`, {});
	}
}
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { environment } from "@environments/environment";
import { ChangePassword } from "@interfaces/changePassword.interface";
import { LoginDetails } from "@interfaces/loginDetails.interface";
import { UpdateProfile } from "@interfaces/updateProfile.interface";
import { AppSettingsService } from "@services/appSettings.service";
import { RequestPasswordResetToken } from "@interfaces/requestPasswordResetToken.interface";
import { Guid } from "guid-typescript";

@Injectable()
export class AuthService {
	authCheck: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
	endpoint = "Auth";
	isAuthed = false;
	refreshTokenTimeout: any = undefined;

	constructor(private http: HttpClient, private appSettingsService: AppSettingsService) {
		if (this.loginCheck() === true) {
			this.isAuthed = true;
			this.authCheck.next(this.isAuthed);
		}
	}

	// API
	checkSupervisor(code: string) {
		return this.http.get(`${environment.server.apiURL}/${this.endpoint}/App_CheckSupervisor/${code}`);
	}

	getCurrentUser() {
		return this.http.get(`${environment.server.apiURL}/${this.endpoint}/ProfileData`);
	}

	getCurrentUserDetails() {
		return this.http.get(`${environment.server.apiURL}/${this.endpoint}/Profile`);
	}

	getCurrentUserIsAdmin() {
		return this.http.get(`${environment.server.apiURL}/${this.endpoint}/CurrentUserIsAdmin`);
	}

	getCurrentUserIsProductionManagement() {
		return this.http.get(`${environment.server.apiURL}/${this.endpoint}/CurrentUserIsProductionManager`);
	}

	getCurrentUserIsQualityManagement() {
		return this.http.get(`${environment.server.apiURL}/${this.endpoint}/CurrentUserIsQualityManager`);
	}

	getCurrentUserIsSuperAdmin() {
		return this.http.get(`${environment.server.apiURL}/${this.endpoint}/CurrentUserIsSuperAdmin`);
	}

	getCurrentUserIsSupervisor() {
		return this.http.get(`${environment.server.apiURL}/${this.endpoint}/CurrentUserIsSupervisor`);
	}

	getEmail() {
		return this.http.get(`${environment.server.apiURL}/${this.endpoint}/Email`);
	}

	getGymAdmin() {
		return this.http.get(`${environment.server.apiURL}/${this.endpoint}/GetGym`);
	}

	getPasswordComplexityRules() {
		return this.http.get(`${environment.server.apiURL}/${this.endpoint}/PasswordRules`);
	}

	getTitles() {
		return this.http.get(`${environment.server.apiURL}/${this.endpoint}/GetTitles`);
	}

	getUser(id: number) {
		return this.http.get(`${environment.server.apiURL}/${this.endpoint}/GetUser/${id}`);
	}

	login(loginDetails: LoginDetails) {
		return this.http.post(
			`${environment.server.apiURL}/${this.endpoint}/CreateToken`,
			loginDetails
		);
	}

	loginAdmin(loginDetails: LoginDetails) {
		return this.http.post(
			`${environment.server.apiURL}/${this.endpoint}/CreateAdminToken`,
			loginDetails
		);
	}

	loginEmployeeCode(id: string) {
		return this.http.post(
			`${environment.server.apiURL}/${this.endpoint}/CreateEmployeeToken/${id}`, {}
		);
	}

	refreshToken() {
		return this.http.post(`${environment.server.apiURL}/${this.endpoint}/RefreshToken`, {});
	}

	requestPasswordResetToken(requestDetails: RequestPasswordResetToken) {
		return this.http.post(`${environment.server.apiURL}/${this.endpoint}/RequestPasswordResetToken`, requestDetails);
	}

	resetPassword(token: Guid, values: ChangePassword) {
		return this.http.patch(`${environment.server.apiURL}/${this.endpoint}/ResetPassword/${token}/Complete`, values);
	}

	updateAccount(profileData: UpdateProfile) {
		return this.http.patch(
			`${environment.server.apiURL}/${this.endpoint}/UpdateProfile`,
			profileData
		);
	}

	updatePassword(values: ChangePassword) {
		return this.http.patch(`${environment.server.apiURL}/${this.endpoint}/UpdatePassword`, values);
	}

	validateResetPaswordToken(token: Guid) {
		return this.http.get(`${environment.server.apiURL}/${this.endpoint}/ResetPassword/${token}`);
	}

	// ACCESS TOKEN CONTROL
	// eslint-disable-next-line @typescript-eslint/member-ordering
	refreshAccessToken(): void {
		this.refreshTokenTimeout = setTimeout(() => {
			this.refreshToken().subscribe((res: any) => {
				this.appSettingsService.set("accessToken", res.response.accessToken);
				this.appSettingsService.set(
					"accessTokenExpiryTimeMilliseconds",
					new Date().getTime() + environment.server.accessTokenExpiryMilliseconds
				);
				this.refreshAccessToken();
			});
		}, environment.server.accessTokenRefreshMilliseconds);
	}

	// eslint-disable-next-line @typescript-eslint/member-ordering
	refreshAccessTokenSingle(): void {
		this.refreshToken().subscribe((res: any) => {
			this.appSettingsService.set("accessToken", res.response.accessToken);
			this.appSettingsService.set(
				"accessTokenExpiryTimeMilliseconds",
				new Date().getTime() + environment.server.accessTokenExpiryMilliseconds
			);
			this.refreshAccessToken();
		});
	}

	// eslint-disable-next-line @typescript-eslint/member-ordering
	clearRefreshInterval(): void {
		clearTimeout(this.refreshTokenTimeout);
	}

	// eslint-disable-next-line @typescript-eslint/member-ordering
	loginCheck() {
		const token = this.appSettingsService.getFromLocalStorage("accessToken");
		const tokenExpiryTime = this.appSettingsService.getFromLocalStorage("accessTokenExpiryTimeMilliseconds");

		if (token && tokenExpiryTime) {
			if (Number(tokenExpiryTime) > new Date().getTime()) {
				return true;
			}
		}

		return false;
	}
}
import { DatePipe } from "@angular/common";
import { Component } from "@angular/core";
import { AdminHeaderButton } from "@interfaces/adminHeaderButton.interface";
import { LogExport } from "@interfaces/logExport.interface";
import { SalesEnquiry } from "@interfaces/salesEnquiry.interface";
import { AuthService } from "@services/auth.service";
import { ExportLogService } from "@services/exportLog.service";
import { SalesEnquiryService } from "@services/salesEnquiry.service";
import { UserProfileService } from "@services/userProfile.service";
import notify from "devextreme/ui/notify";

@Component({
	moduleId: module.id,
	selector: "salesEnquiries-feasibility",
	styleUrls: ["salesEnquiries-feasibility.css"],
	templateUrl: "salesEnquiries-feasibility.html"
})
export class SalesEnquiriesFeasibilityAdminComponent {
	deleteEnabled = true;
	editEnabled = true;
	headerPrimaryButtons: AdminHeaderButton[] = [];
	headerSecondaryButtons: AdminHeaderButton[] = [];
	isProductionManagement = false;
	isQualityManagement = false;
	itemType = "Sales Enquiry"
	itemUrl: string = location.href.split("/")[3] + "/" + location.href.split("/")[4];
	items: SalesEnquiry[] = [];
	popupCloseButtonOptions: any = { onClick: () => this.closePopup(), text: "Close" };
	popupSalesEnquiry: SalesEnquiry = new SalesEnquiry();
	popupSaveButtonOptions: any;
	popupTitle = "";
	popupVisible = false;
	title: string;
	users: any[]=[];
	yesNoDropdown: any = [{ id: true, name: "Yes" }, { id: false, name: "No" }];
	
	constructor(private authService: AuthService, private exportLogService: ExportLogService, private itemService: SalesEnquiryService, private userProfileService: UserProfileService) {
		this.title = "Sales Enquiry Feasibility";
		this.isSignOffAsProductionAvailable = this.isSignOffAsProductionAvailable.bind(this);
		this.isSignOffAsQualityAvailable = this.isSignOffAsQualityAvailable.bind(this);
		this.signOffProductionPopup = this.signOffProductionPopup.bind(this);
		this.signOffQualityPopup = this.signOffQualityPopup.bind(this);
		this.getIsProductionManagement();
		this.getIsQualityManagement();
		this.getItems();
		this.getUsers();
	}
	
	closePopup() {
		this.popupVisible = false;
	}
	
	formatDateTime(dt: Date)
	{
		const datepipe: DatePipe = new DatePipe("en-GB");
		const formattedDate = datepipe.transform(dt, "dd-MMM-YYYY");
		return formattedDate;
	}

	getIsProductionManagement() {		
		this.authService.getCurrentUserIsProductionManagement().subscribe(
			(res: any) => {
				this.isProductionManagement = res.response;
			}
		);
	}

	getIsQualityManagement() {		
		this.authService.getCurrentUserIsQualityManagement().subscribe(
			(res: any) => {
				this.isQualityManagement = res.response;
			}
		);
	}

	getItems() {
		this.itemService.getRequiringFeasibilitySignOff()
			.subscribe(
				(res: any) => this.items = res.response,
				(err) => {
					console.log(err);
					notify("Something went wrong!", "error", 5000);
				},
			);
	}

	getUsers() {
		this.userProfileService.getActiveUsers()
			.subscribe(
				(res: any) => this.users = res.response,
				(err) => {
					console.log(err);
					notify("Something went wrong!", "error", 5000);
				},
			);
	}

	headerButtonClick(method: any) {
		// @ts-ignore // Required to be able to call the method directly from the variable
		if (this[method]) this[method]();
	}

	isSignOffAsProductionAvailable(e: any) {
		return this.isProductionManagement && (e.row.data.productionSignedOffById === undefined || e.row.data.productionSignedOffById === null || e.row.data.productionSignedOffById < 1);
	}

	isSignOffAsQualityAvailable(e: any) {
		return this.isQualityManagement && (e.row.data.qualitySignedOffById === undefined || e.row.data.qualitySignedOffById === null || e.row.data.qualitySignedOffById < 1);
	}

	logExport() {
		const logExport = new LogExport();
		logExport.gridName = "Sales Enquiry Feasibility";
		this.exportLogService.logExport(logExport)
			.subscribe(
				() => console.log("Export Logged"),
				(err) => {
					console.log(err);
					notify("Something went wrong!", "error", 5000);
					return false;
				},
			);
	}

	renderUser(id?: number){
		if (id === undefined || id === null) {
			return "";
		}
		
		const element = this.users.find(x => x.id === id);

		return element!.fullName;
	}

	signOffProduction() {
		if (this.isProductionManagement === false) {
			return;
		}
		this.itemService.feasibilitySignOffProduction(this.popupSalesEnquiry.id)
			.subscribe(
				() => {
					this.popupVisible = false;
					this.getItems();
				},
				(err: any) => {
					notify("Could not sign off as production", "Error", 5000);
					console.log(err);
				}
			);
	}

	signOffProductionPopup(e: any) {
		if (this.isProductionManagement === false) {
			return false;
		}
		this.popupSalesEnquiry = e.row.data;
		this.popupTitle = "Production Sign Off";
		this.popupVisible = true;
		this.popupSaveButtonOptions = { onClick: () => this.signOffProduction(), text: "Production Sign Off" };
		return false;
	}

	signOffQuality() {
		if (this.isQualityManagement === false) {
			return;
		}
		this.itemService.feasibilitySignOffQuality(this.popupSalesEnquiry.id)
			.subscribe(
				() => {
					this.popupVisible = false;
					this.getItems();
				},
				(err: any) => {
					notify("Could not sign off as quality", "Error", 5000);
					console.log(err);
				}
			);
	}

	signOffQualityPopup(e: any) {
		if (this.isQualityManagement === false) {
			return false;
		}
		this.popupSalesEnquiry = e.row.data;
		this.popupTitle = "Quality Sign Off";
		this.popupVisible = true;
		this.popupSaveButtonOptions = { onClick: () => this.signOffQuality(), text: "Quality Sign Off" };
		return false;
	}

	signedOffYesNo(cellInfo: any){		
		if (cellInfo.value === undefined || cellInfo.value === null || cellInfo.value <= 0)
		{
			return "NO";
		}
		else
		{
			return "YES";
		}
	}
}
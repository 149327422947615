<div class="dx-viewport vh-100">
	<dx-drawer *ngIf="loggedIn" [closeOnOutsideClick]="false" [(opened)]="isDrawerOpen" revealMode="expand" template="template" [class.staging]="staging">
		<div *dxTemplate="let data of 'template'">
			<div class="layout-sidebar" style="width: 300px">
				<img class="image-fluid" style="margin-bottom: 20px; margin-left: 25%; margin-right: 25%; margin-top: 10px; width: 50%;" src="../../../assets/Logo.png">
				<dx-list *ngIf="menuItems.length > 0" class="panel-list" [dataSource]="menuItems" [hoverStateEnabled]="false" [activeStateEnabled]="false" [focusStateEnabled]="false" (onItemClick)="navigate($event.itemData)">
					<div *dxTemplate="let item of 'item'" class="panel-list-item" [class.active]="isActiveRoute(item.route)">
						<i class="{{item.icon}} d-inline-block fs-6 black me-3"></i>
						<div class="d-inline-block fs-6 black">{{item.text}}</div>
					</div>
				</dx-list>
				<div class="position-absolute" style="bottom: 25px;">
					<div class="panel-list-item" (click)="navigateUrl('admin/help')">
						<i class="fal fa-info-circle d-inline-block fs-6 black me-3"></i>
						<div class="d-inline-block fs-6 black">Help</div>
					</div>
					<div class="panel-list-item" (click)="navigateUrl('admin/user-settings')">
						<i class="fal fa-user-cog d-inline-block fs-6 black me-3"></i>
						<div class="d-inline-block fs-6 black">User Settings</div>
					</div>
					<div class="panel-list-item" (click)="logout()">
						<i class="fal fa-sign-out d-inline-block fs-6 red me-3"></i>
						<div class="d-inline-block fs-6 red">Logout</div>
					</div>
				</div>
			</div>
		</div>
		<div class="d-flex">
			<div *ngIf="windowWidthCheck()" class="layout-sidebar-mobile-menu">
				<img class="image-fluid" style="margin-top: 25px; transform: rotate(-90deg); width: 50px;" src="../../../assets/Logo.png">
				<div class="position-absolute" style="bottom: 35px;">
					<div style="padding-left: 18px; padding-right: 18px" (click)="toggleSideDrawer()">
						<i class="fal fa-bars d-inline-block fs-6 black"></i>
					</div>
				</div>
			</div>
			<div class="container" id="content">
				<div *ngIf="staging" id="stagingBanner" style="margin-bottom:10px !important">THIS IS A STAGING SITE, THIS IS NOT A PRODUCTION SYSTEM</div>
				<div *ngIf="displayBanner === true" id="adminBanner">{{bannerText}}</div>
				<router-outlet></router-outlet>
				<modal></modal>
			</div>
		</div>
	</dx-drawer>
	<div *ngIf="!loggedIn" class="limiter">
		<div *ngIf="showLoginForm" class="container-login100">
			<div class="wrap-login100">
				<div class="login100-pic " data-tilt="">
					<img style="margin-top:25%;" src="../../../assets/Logo.png">
				</div>
				<form class="login100-form validate-form">
					<span class="login100-form-title">
						Welcome to Flow <span *ngIf="this.staging"> Staging</span>
					</span>
					<div class="wrap-input100 validate-input" data-validate="Valid email is required: ex@abc.xyz">
						<input class="input100" type="text" name="email"  [(ngModel)]="loginDetails.login" placeholder="Email"
							style="background-image: url(&quot;data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAASCAYAAABSO15qAAAAAXNSR0IArs4c6QAAAPhJREFUOBHlU70KgzAQPlMhEvoQTg6OPoOjT+JWOnRqkUKHgqWP4OQbOPokTk6OTkVULNSLVc62oJmbIdzd95NcuGjX2/3YVI/Ts+t0WLE2ut5xsQ0O+90F6UxFjAI8qNcEGONia08e6MNONYwCS7EQAizLmtGUDEzTBNd1fxsYhjEBnHPQNG3KKTYV34F8ec/zwHEciOMYyrIE3/ehKAqIoggo9inGXKmFXwbyBkmSQJqmUNe15IRhCG3byphitm1/eUzDM4qR0TTNjEixGdAnSi3keS5vSk2UDKqqgizLqB4YzvassiKhGtZ/jDMtLOnHz7TE+yf8BaDZXA509yeBAAAAAElFTkSuQmCC&quot;); background-repeat: no-repeat; background-attachment: scroll; background-size: 16px 18px; background-position: 98% 50%;">
						<span class="focus-input100"></span>
						<span class="symbol-input100">
							<i class="fa fa-envelope" aria-hidden="true"></i>
						</span>
						<span *ngIf="validationErrors.login !== ''" class="text-danger text-nowrap">{{validationErrors.login}}</span>
					</div>
					<div class="wrap-input100 validate-input" data-validate="Password is required">
						<input class="input100" type="password" name="pass" placeholder="Password" [(ngModel)]="loginDetails.password" autocomplete="off"
							style="background-image: url(&quot;data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAASCAYAAABSO15qAAAAAXNSR0IArs4c6QAAAPhJREFUOBHlU70KgzAQPlMhEvoQTg6OPoOjT+JWOnRqkUKHgqWP4OQbOPokTk6OTkVULNSLVc62oJmbIdzd95NcuGjX2/3YVI/Ts+t0WLE2ut5xsQ0O+90F6UxFjAI8qNcEGONia08e6MNONYwCS7EQAizLmtGUDEzTBNd1fxsYhjEBnHPQNG3KKTYV34F8ec/zwHEciOMYyrIE3/ehKAqIoggo9inGXKmFXwbyBkmSQJqmUNe15IRhCG3byphitm1/eUzDM4qR0TTNjEixGdAnSi3keS5vSk2UDKqqgizLqB4YzvassiKhGtZ/jDMtLOnHz7TE+yf8BaDZXA509yeBAAAAAElFTkSuQmCC&quot;); background-repeat: no-repeat; background-attachment: scroll; background-size: 16px 18px; background-position: 98% 50%;">
						<span class="focus-input100"></span>
						<span class="symbol-input100">
							<i class="fa fa-lock" aria-hidden="true"></i>
						</span>
						<span *ngIf="validationErrors.password !== ''" class="text-danger">{{validationErrors.password}}</span>
					</div>
					<div class="container-login100-form-btn">
						<button class="login100-form-btn" (click)="login()">
							Login
						</button>
					</div>
					<div class="text-center p-t-12" style="margin-top:20px; ">
						<a class="txt2" href="/admin/forgotPassword">
							Forgot Password?
						</a>
					</div>
				</form>
			</div>
		</div>
		<!-- <div *ngIf="showLoginForm" class="login-div position-absolute top-50 start-50 translate-middle">
			 <div class="login-div-left">
				<img src="../../../assets/Logo.png" style="vertical-align: middle;">
			</div>
			<div class="login-div-right position-relative">
				<h4 class="mx-3 mt-3 pb-3">Welcome to {{systemName}}</h4>
				<form class="login-form mx-3">
					<div class="input-group pb-3">
						<span class="input-group-text" id="basic-addon1"><i class="fal fa-envelope"></i></span>
						<input class="form-control shadow-none" type="text" placeholder="Email" name="email" [(ngModel)]="loginDetails.login">
						<span *ngIf="validationErrors.login !== ''" class="text-danger text-nowrap">{{validationErrors.login}}</span>
					</div>
					<div class="input-group pb-3">
						<span class="input-group-text" id="basic-addon1"><i class="fal fa-lock"></i></span>
						<input class="form-control shadow-none" type="password" placeholder="Password" name="password" [(ngModel)]="loginDetails.password">
						<span *ngIf="validationErrors.password !== ''" class="text-danger">{{validationErrors.password}}</span>
					</div>
					<button class="btn btn-block text-uppercase shadow-none w-100 login-button mb-3" (click)="login()">Log in</button>
					<div class="w-auto">
						<p class="m-auto pointer" (click)="forgottenPassword()">Forgotten your password?</p>
					</div>
				</form>
				<span class="position-absolute bottom-0 start-0 mb-1 mx-3">{{copyright}}</span>
			</div>
		</div> -->
		<router-outlet *ngIf="!showLoginForm"></router-outlet>
	</div>
</div>